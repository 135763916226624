import { AssetCollectionAttributes } from '../model/AssetCollectionAttribute';
import {
    MediaAssetAttributesPerAssetFamily,
    AltTextAssetAttributesPerAssetFamily,
} from '../model/AssetAttributesPerAssetFamily';
import { apiFetch } from '../../../../../../util/apiFetch';

type Result = {
    assetCollectionAttributes: AssetCollectionAttributes[];
    mediaAssetAttributesPerAssetFamily: {
        [asset_family_code: string]: MediaAssetAttributesPerAssetFamily[];
    };
    altTextAssetAttributesPerAssetFamily: {
        [asset_family_code: string]: AltTextAssetAttributesPerAssetFamily[];
    };
};

export const getPimAssetAttributes = (storeId: string, scope: string) =>
    apiFetch<Result>(`${storeId}/mapping/pim-asset-attributes/${scope}`).then((result) => result);
