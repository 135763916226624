import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../../../component';
import { getPimAttributes } from '../api/getPimAttributes';
import { getPimFamilies } from '../api/getPimFamilies';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { useCurrentStore } from '../../../MultiStore';
import { getVariantMapping } from '../api/getVariantMapping';
import { getShopifyVariantAttributes } from '../api/getShopifyVariantAttributes';
import { InstabilityStickyHelper } from '../../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../../util/InstabilityType';

type Props = {
    children: React.ReactNode;
};

export const ProductVariantMappingProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { currentStoreId } = useCurrentStore();
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);

    const { isFetching } = useQuery(
        'fetchAllData',
        () =>
            Promise.all([
                getShopifyVariantAttributes(currentStoreId),
                getPimAttributes(currentStoreId),
                getPimFamilies(currentStoreId),
                getVariantMapping(currentStoreId),
            ]),
        {
            onSuccess: ([
                shopifyAttributesResult,
                pimAttributesResult,
                pimFamiliesResult,
                productVariantMapping,
            ]) => {
                if (
                    pimFamiliesResult.error &&
                    pimFamiliesResult.error === InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);

                    return;
                }

                if (
                    pimAttributesResult.error &&
                    pimAttributesResult.error === InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);

                    return;
                }
                dispatch({
                    type: 'fetchAllData/fulfilled',
                    shopifyAttributes: shopifyAttributesResult.shopifyAttributes,
                    shopifyAttributeSections: shopifyAttributesResult.shopifyAttributeSections,
                    pimAttributes: pimAttributesResult.pimAttributes,
                    pimFamilies: pimFamiliesResult.families,
                    productVariantMapping,
                });
            },
        },
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <>
            {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
            </StateContext.Provider>
        </>
    );
};
