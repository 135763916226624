import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

export const DisabledConfiguration = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        defaultMessage:
            'Your configuration page is temporarily disabled as the synchronization is in progress.',
        id: 'Pz0DN+',
    });

    return <Placeholder illustration={<ApiIllustration />} size='large' title={title} />;
};
