import { Immutable, produce } from 'immer';

export type State = Immutable<{
    metaObjectMapping: string;
    mappingIsDirty: boolean;
}>;

export const initialState: State = {
    metaObjectMapping: '',
    mappingIsDirty: false,
};

export type Action =
    | {
          type: 'metaObjectMapping/fetch/sourceAndMapping';
          metaObjectMapping: string;
      }
    | {
          type: 'metaObjectMapping/source/saved';
          mappingIsDirty: boolean;
      }
    | {
          type: 'metaObjectMapping/mapping/update';
          newValue: string;
      };

export const reducer = produce<(state: State, action: Action) => State>((state, action) => {
    switch (action.type) {
        case 'metaObjectMapping/source/saved':
            state.mappingIsDirty = action.mappingIsDirty;
            break;
        case 'metaObjectMapping/fetch/sourceAndMapping':
            state.metaObjectMapping = action.metaObjectMapping;
            state.mappingIsDirty = false;
            break;
        case 'metaObjectMapping/mapping/update':
            state.metaObjectMapping = action.newValue;

            try {
                JSON.parse(action.newValue);
                // If we are here, the json is valid, and we can allow to save it
                state.mappingIsDirty = true;
            } catch (error) {
                // The json is wrong and we disable the save
                state.mappingIsDirty = false;
            }

            break;
    }

    return state;
});
