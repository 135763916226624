import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isEmpty: boolean;
};

export const isMetafieldAttributeMappingEmpty = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/synchronization/is-metafield-attribute-mapping-empty`).then(
        (result) => result,
    );
