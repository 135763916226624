import { CloseIcon, IconButton, SelectInput, Table } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '../store/StoreProvider';
import styled from 'styled-components';
import { ImageAttribute } from '../model/ImageAttribute';

const AttributeSelectContainer = styled.div`
    width: 300px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    imageAttribute: ImageAttribute | null;
};

export const ImageAttributeItem = ({ imageAttribute }: Props) => {
    const intl = useIntl();
    const { allowedAttributes } = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleChange = (newValue: string | null) => {
        if (!imageAttribute && newValue) {
            dispatch({
                type: 'mediaMapping/mapping/new',
                newAttribute: newValue,
            });
        }

        if (imageAttribute && newValue) {
            dispatch({
                type: 'mediaMapping/mapping/update',
                previousValue: imageAttribute.code,
                newValue: newValue,
            });
        }
    };

    const handleRemove = () => {
        if (imageAttribute) {
            dispatch({
                type: 'mediaMapping/mapping/remove',
                attributeToRemove: imageAttribute.code,
            });
        }
    };

    return (
        <>
            <Table.Cell>
                <AttributeSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'yttaVP',
                            defaultMessage: 'No image to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'ArzMDa',
                            defaultMessage: 'Select an image to map',
                        })}
                        value={imageAttribute?.label ?? null}
                        openLabel={''}
                        onChange={(newValue: string) => handleChange(newValue)}
                        clearable={false}
                    >
                        {Array.from(allowedAttributes.values())
                            .sort((i1, i2) => (i1.label > i2.label ? 1 : -1))
                            .map((imageAttribute) => (
                                <SelectInput.Option
                                    key={imageAttribute.code}
                                    value={imageAttribute.code}
                                >
                                    {imageAttribute.label}
                                </SelectInput.Option>
                            ))}
                    </SelectInput>
                </AttributeSelectContainer>
            </Table.Cell>
            {imageAttribute ? (
                <Table.Cell>
                    <RemoveButtonContainer>
                        <IconButton
                            icon={<CloseIcon />}
                            title={intl.formatMessage({
                                id: 'S53Eji',
                                defaultMessage: 'Remove attribute',
                            })}
                            onClick={() => handleRemove()}
                            ghost='borderless'
                            level='tertiary'
                        />
                    </RemoveButtonContainer>
                </Table.Cell>
            ) : (
                <Table.Cell />
            )}
        </>
    );
};
