import { AttributesIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const NoPimAttributeInSearch = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'BOGI5k',
        defaultMessage: 'Sorry, there is no attribute for your search.',
    });

    return (
        <>
            <Placeholder illustration={<AttributesIllustration />} size='large' title={title}>
                <FormattedMessage
                    id='ut1YF6'
                    defaultMessage='Try again with new search criteria.'
                />
            </Placeholder>
        </>
    );
};
