import { apiFetch } from '../../../../util/apiFetch';
import { SecondaryLocalesMapped } from '../model/SecondaryLocalesModel';

type Result = {
    secondaryLocalesMapping: SecondaryLocalesMapped;
};

export const getSecondaryLocalesMapped = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/retrieve-secondary-locales-mapping`).then(
        (result) => {
            return result.secondaryLocalesMapping;
        },
    );
