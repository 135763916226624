import React from 'react';
import { useQuery } from 'react-query';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { MediaSource } from '../../model/MediaSource';
import { useCurrentStore } from '../../../../MultiStore';
import { getMediaMapping } from './api/getMediaMapping';
import { Loading } from '../../../../../component';
import { MediaMappingScope } from '../../model/MediaMappingScope';

type Props = {
    children: React.ReactNode;
    mediaMappingScope: MediaMappingScope;
};

export const MediaMappingProvider = ({ children, mediaMappingScope }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { currentStoreId } = useCurrentStore();

    const { isFetching } = useQuery(
        [currentStoreId, 'getMediaMapping'],
        () => getMediaMapping(currentStoreId, mediaMappingScope),
        {
            onSuccess: (result) => {
                dispatch({
                    type: 'setMediaMappingScope',
                    mediaMappingScope: mediaMappingScope,
                });
                if (result.source === MediaSource.Images) {
                    dispatch({
                        type: 'mediaMapping/fetch/sourceAndMapping',
                        mediaSource: result.source,
                        mediaMapping: result.mediaMapping,
                    });
                } else if (result.source === MediaSource.Assets) {
                    dispatch({
                        type: 'assetMapping/fetch/sourceAndMapping',
                        mediaSource: result.source,
                        assetMapping: result.mediaMapping,
                    });
                }
            },
        },
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};
