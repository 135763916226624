import { AttributesIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

export const NoPimAttribute = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: '9pBRzf',
        defaultMessage: 'There is no attribute',
    });

    return (
        <>
            <Placeholder illustration={<AttributesIllustration />} size='large' title={title} />
        </>
    );
};
