import { ClientErrorIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
    isConfigInvalid: boolean;
};

export const InvalidConfiguration = ({ isConfigInvalid }: Props) => {
    const intl = useIntl();

    const title = isConfigInvalid
        ? intl.formatMessage({
              id: 'w8otdg',
              defaultMessage:
                  'Please finish the store configuration in the Configuration page, then you will be able to start mapping.',
          })
        : '';

    return <Placeholder illustration={<ClientErrorIllustration />} size='large' title={title} />;
};
