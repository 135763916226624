import { MarketsPricingMapping } from '../model/MarketsPricingModel';
import { apiFetch } from '../../../../util/apiFetch';

type Result = {
    mapping: MarketsPricingMapping;
    error?:
        | string
        | {
              code: number;
              violations: {
                  [mappingRow: string]: { [fieldKey: string]: string[] };
              };
          };
};

export const getMarketsPricingMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/get-markets-pricing-mapping`).then(
        (result) => result,
    );
