import { apiFetch, BadRequestError } from '../../../../util/apiFetch';
import { Immutable } from 'immer';
import { PimAttributeErrors } from '../model/PimAttributeErrors';

type SaveAttributeMapping = {
    hasAttributePerFamily: boolean;
    hasCollectionOfAttribute: boolean;
    data:
        | string
        | readonly string[]
        | { [pimFamilyCode: string]: string | null }
        | { [pimFamilyCode: string]: string[] | null };
};

export type Data = {
    [shopifyAttributeCode: string]: Immutable<SaveAttributeMapping>;
};

type Result = {};

export const saveMapping = (data: Data, currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/mapping/save-product-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((result) => result);
export const isSaveProductMappingValidationError = (
    error: unknown,
): error is BadRequestError<{
    errors: PimAttributeErrors;
}> => error instanceof BadRequestError;
