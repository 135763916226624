import { apiFetch } from '../../../../util/apiFetch';

type Result = {
    activatedShopifyLanguages: [
        {
            language: string;
            name: string;
        },
    ];
    pimEnabledLocales: [
        {
            code: string;
            enabled: boolean;
        },
    ];
    error?: string;
};

export const getSecondaryLocalesQueryKey = 'getSecondaryLocales';

export const getSecondaryLocales = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/retrieve-secondary-locales`).then((result) => {
        return result;
    });
