import { AssociationTypesIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const NoPimAssociationInSearch = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'Sv3Vwr',
        defaultMessage: 'Sorry, there is no association for your search.',
    });

    return (
        <>
            <Placeholder illustration={<AssociationTypesIllustration />} size='large' title={title}>
                <FormattedMessage
                    id='ut1YF6'
                    defaultMessage='Try again with new search criteria.'
                />
            </Placeholder>
        </>
    );
};
