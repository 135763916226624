import React from 'react';
import { SectionTitle, Table, Tooltip, getColor } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TableRow } from 'akeneo-design-system/lib/components/Table/TableRow/TableRow';
import { ConfigurationModel, SynchronizationModel } from '../../model/SynchronizationReportModel';
import { SynchronizationScope } from '../../../Synchronization/model/SynchronizationScope';
import { DateTime } from 'luxon';
import { ConfigurationReportContent } from './ConfigurationReportContent';

const ConfigurationReportContainer = styled.div`
    width: 100%;
`;

const Label = styled.div`
    color: ${getColor('brand', 100)};
    width: 30%;
    align-self: center;
`;

const TableCellContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const MarketContainer = styled.div`
    display: flex;
    align-items: center;
`;

type Props = {
    configuration: ConfigurationModel | null;
    synchronization: SynchronizationModel | null;
};

export const ConfigurationReport = ({ configuration, synchronization }: Props) => {
    let filters: boolean = false;
    if (
        configuration!.filters.filter_attribute ||
        configuration!.filters.min_completeness ||
        configuration?.filters.pre_existing_catalog_only
    ) {
        filters = true;
    }

    return (
        <>
            <ConfigurationReportContainer>
                <SectionTitle>
                    <SectionTitle.Title>
                        {!configuration?.is_scheduled_sync ? (
                            <FormattedMessage
                                id='/o6xSU'
                                defaultMessage='Manual synchronization - configuration'
                            />
                        ) : (
                            <FormattedMessage
                                id='+6jJYc'
                                defaultMessage='Scheduled synchronization - configuration'
                            />
                        )}
                    </SectionTitle.Title>
                </SectionTitle>

                <Table>
                    <Table.Body>
                        <TableRow>
                            <Table.Cell>
                                <Label>
                                    <FormattedMessage id='t4Jiip' defaultMessage='Import process' />
                                </Label>
                            </Table.Cell>
                            <Table.Cell>
                                <TableCellContent>
                                    {/* Scope */}
                                    {configuration!.scope === SynchronizationScope.Product && (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='05FPn1'
                                                    defaultMessage='Scope: Products'
                                                />
                                            </div>
                                        </>
                                    )}
                                    {configuration!.scope ===
                                        SynchronizationScope.ProductVariant && (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='UQPWUJ'
                                                    defaultMessage='Scope: Products with variants'
                                                />
                                            </div>
                                        </>
                                    )}

                                    {/* Delta import */}
                                    {configuration!.type === 'FULL' ? (
                                        <>
                                            <div>
                                                <FormattedMessage
                                                    id='C8iUSa'
                                                    defaultMessage='Full import: {created_at}'
                                                    values={{
                                                        created_at:
                                                            DateTime.fromISO(
                                                                synchronization!.created_at,
                                                            ).toLocaleString(DateTime.DATE_SHORT) +
                                                            ' - ' +
                                                            DateTime.fromISO(
                                                                synchronization!.created_at,
                                                            ).toLocaleString(
                                                                DateTime.TIME_WITH_SECONDS,
                                                            ),
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        [
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='Uc7E6b'
                                                        defaultMessage='Delta import: {created_at}'
                                                        values={{
                                                            created_at:
                                                                DateTime.fromISO(
                                                                    synchronization!.created_at,
                                                                ).toLocaleString(
                                                                    DateTime.DATE_SHORT,
                                                                ) +
                                                                ' - ' +
                                                                DateTime.fromISO(
                                                                    synchronization!.created_at,
                                                                ).toLocaleString(
                                                                    DateTime.TIME_WITH_SECONDS,
                                                                ),
                                                        }}
                                                    />
                                                </div>
                                            </>,
                                        ]
                                    )}

                                    {/* Reference date */}
                                    {configuration!.reference_date && (
                                        <div>
                                            <FormattedMessage
                                                id='PEVUwW'
                                                defaultMessage='Reference date: {reference_date}'
                                                values={{
                                                    reference_date:
                                                        DateTime.fromISO(
                                                            configuration!.reference_date,
                                                        ).toLocaleString(DateTime.DATE_SHORT) +
                                                        ' - ' +
                                                        DateTime.fromISO(
                                                            configuration!.reference_date,
                                                        ).toLocaleString(
                                                            DateTime.TIME_WITH_SECONDS,
                                                        ),
                                                }}
                                            />
                                        </div>
                                    )}
                                </TableCellContent>
                            </Table.Cell>
                        </TableRow>
                        {filters && (
                            <TableRow>
                                <Table.Cell>
                                    <Label>
                                        <FormattedMessage id='zSOvI0' defaultMessage='Filters' />
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <TableCellContent>
                                        {/* Filter attribute */}
                                        {configuration!.filters.filter_attribute && (
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='1ftOA4'
                                                        defaultMessage='Filter attribute: {filter_attribute}'
                                                        values={{
                                                            filter_attribute:
                                                                configuration!.filters
                                                                    .filter_attribute,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {/* Completeness */}
                                        {configuration!.filters.min_completeness && (
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='jQ/0mu'
                                                        defaultMessage='Completeness: {min_completeness}%'
                                                        values={{
                                                            min_completeness:
                                                                configuration!.filters
                                                                    .min_completeness,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {/* Pre-existing products only */}
                                        {configuration?.filters.pre_existing_catalog_only && (
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='KQTGI1'
                                                        defaultMessage='Pre-existing products only'
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </TableCellContent>
                                </Table.Cell>
                            </TableRow>
                        )}

                        <TableRow>
                            <Table.Cell>
                                <Label>
                                    <FormattedMessage id='Jq3FDz' defaultMessage='Content' />
                                </Label>
                            </Table.Cell>
                            <Table.Cell>
                                <TableCellContent>
                                    <div>
                                        {configuration && (
                                            <ConfigurationReportContent
                                                configuration={configuration}
                                            />
                                        )}
                                    </div>
                                    {/* Locales:  */}
                                    <div>
                                        <FormattedMessage
                                            id='W/1F/j'
                                            defaultMessage='Locales: {locales}'
                                            values={{
                                                locales: configuration!.locales.join(', '),
                                            }}
                                        />
                                    </div>
                                    {/* Market pricing: */}
                                    {configuration!.markets.length > 0 && (
                                        <MarketContainer>
                                            <FormattedMessage
                                                id='sITCHu'
                                                defaultMessage='Markets'
                                            />
                                            <Tooltip iconSize={16}>
                                                <ul>
                                                    {configuration!.markets.map((market) => (
                                                        <li>{market.market_name}</li>
                                                    ))}
                                                </ul>
                                            </Tooltip>
                                        </MarketContainer>
                                    )}
                                </TableCellContent>
                            </Table.Cell>
                        </TableRow>
                    </Table.Body>
                </Table>
            </ConfigurationReportContainer>
        </>
    );
};
