import styled from 'styled-components';
import { CloseIcon, Helper, IconButton, SelectInput, Table, Tooltip } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from '../store/StoreProvider';
import React from 'react';
import { MarketsPricingType } from '../model/MarketsPricingModel';

const FieldContainer = styled.div`
    width: 350px;
`;

const FieldErrorContainer = styled.div`
    padding-top: 5px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    marketPricing: MarketsPricingType | null;
    index: number | null;
};
export const MarketsPricingItem = ({ marketPricing, index }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { shopifyMarkets, allowedShopifyMarkets, pimLocales } = useSelector((state) => state);

    const handleChange = (
        newSelectedShopifyMarket: string | null,
        marketName: string | null,
        newSelectedLocale: string | null,
        index: number | null,
    ) => {
        if (null === marketPricing) {
            dispatch({
                type: 'marketsPricingMapping/mapping/new',
                marketId: newSelectedShopifyMarket,
                marketName: marketName,
                locale: newSelectedLocale,
            });
        } else {
            dispatch({
                type: 'marketsPricingMapping/mapping/update',
                index: index ?? 0,
                marketId: newSelectedShopifyMarket,
                marketName: marketName,
                locale: newSelectedLocale,
            });
        }
    };

    const handleRemove = () => {
        if (marketPricing?.marketId) {
            dispatch({
                type: 'marketsPricingMapping/mapping/remove',
                marketToRemove: marketPricing.marketId,
            });
        }
    };

    return (
        <>
            <Table.Cell>
                <FieldContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'BNNViQ',
                            defaultMessage: 'No Shopify market to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'WL6ub4',
                            defaultMessage: 'Select a Shopify market',
                        })}
                        value={marketPricing?.marketName ?? null}
                        openLabel={''}
                        clearable={false}
                        onChange={(selectedShopifyMarket: string) =>
                            handleChange(
                                selectedShopifyMarket,
                                allowedShopifyMarkets.find((el) => el.id === selectedShopifyMarket)
                                    ?.name ?? null,
                                marketPricing?.locale ?? null,
                                index,
                            )
                        }
                    >
                        {Array.from(allowedShopifyMarkets.values())
                            .sort((i1, i2) => (i1.name > i2.name ? 1 : -1))
                            .map((shopifyMarket, index) => (
                                <SelectInput.Option
                                    key={shopifyMarket.id ?? index}
                                    value={shopifyMarket.id}
                                >
                                    {shopifyMarket.name}
                                </SelectInput.Option>
                            ))}
                    </SelectInput>
                    {marketPricing?.errors && (
                        <FieldErrorContainer>
                            {undefined !== marketPricing.errors.marketId &&
                                marketPricing.errors.marketId.map((error, index) => (
                                    <Helper level='error' inline key={index}>
                                        {error}
                                    </Helper>
                                ))}
                            {undefined !== marketPricing.errors.marketName &&
                                marketPricing.errors.marketName.map((error, index) => (
                                    <Helper level='error' inline key={index}>
                                        {error}
                                    </Helper>
                                ))}
                        </FieldErrorContainer>
                    )}
                </FieldContainer>
            </Table.Cell>
            {marketPricing ? (
                <Table.Cell>
                    <FieldContainer>
                        {shopifyMarkets?.filter(
                            (market) => market.id === marketPricing?.marketId,
                        )[0]?.currency ?? marketPricing.currency}
                        {marketPricing?.errors && (
                            <FieldErrorContainer>
                                {undefined !== marketPricing.errors.currency &&
                                    marketPricing.errors.currency.map((error) => (
                                        <Helper level='error' inline>
                                            {error}
                                        </Helper>
                                    ))}
                            </FieldErrorContainer>
                        )}
                    </FieldContainer>
                </Table.Cell>
            ) : (
                <Table.Cell>
                    <FieldContainer />
                </Table.Cell>
            )}
            <Table.Cell>
                <FieldContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'qbtmRo',
                            defaultMessage: 'No PIM locale to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'GQbx9l',
                            defaultMessage: 'Select a PIM locale',
                        })}
                        value={marketPricing?.locale ?? null}
                        openLabel={''}
                        onChange={(selectedPimLocale: string) =>
                            handleChange(
                                marketPricing?.marketId ?? null,
                                marketPricing?.marketName ?? null,
                                selectedPimLocale,
                                index,
                            )
                        }
                    >
                        {Object.entries(pimLocales || {}).map(([code, label]) => (
                            <SelectInput.Option key={code} value={code}>
                                {label}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                    {marketPricing?.errors && (
                        <FieldErrorContainer>
                            {undefined !== marketPricing.errors.locale &&
                                marketPricing.errors.locale.map((error) => (
                                    <Helper level='error' inline>
                                        {error}
                                    </Helper>
                                ))}
                        </FieldErrorContainer>
                    )}
                </FieldContainer>
                {!marketPricing && (
                    <Tooltip
                        width={350}
                        children={intl.formatMessage({
                            id: 'viWlZ3',
                            defaultMessage:
                                'Please complete this field if at least one of your price attributes is localizable. Values will be retrieved from this locale.',
                        })}
                    />
                )}
            </Table.Cell>
            {marketPricing?.marketId ? (
                <Table.Cell>
                    <RemoveButtonContainer>
                        <IconButton
                            icon={<CloseIcon />}
                            title={intl.formatMessage({
                                id: 'gS4yhH',
                                defaultMessage: 'Remove Shopify market',
                            })}
                            onClick={() => handleRemove()}
                            ghost='borderless'
                            level='tertiary'
                        />
                    </RemoveButtonContainer>
                </Table.Cell>
            ) : (
                <Table.Cell>
                    <RemoveButtonContainer />
                </Table.Cell>
            )}
        </>
    );
};
