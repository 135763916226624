import { RunningSynchronizationGuard } from '../../Synchronization';
import { DisabledMapping } from '../../NativeFields/component/DisabledMapping';
import { ValidConfigurationGuard } from '../../Configuration';
import { InvalidConfiguration } from '../../NativeFields/component/InvalidConfiguration';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { AssetCollectionList } from './component/AssetCollectionList';
import { MetafieldAssetCollectionsProvider } from './MetafieldAssetCollectionsProvider';

const MAX_ASSETS_ATTRIBUTES = 10;

export const MetafieldAssetCollections = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(isConfigInvalid) => (
                    <InvalidConfiguration isConfigInvalid={isConfigInvalid} />
                )}
            >
                <MetafieldAssetCollectionsProvider>
                    <Routes>
                        <Route
                            path='*'
                            element={<AssetCollectionList maxAttribute={MAX_ASSETS_ATTRIBUTES} />}
                        />
                    </Routes>
                </MetafieldAssetCollectionsProvider>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
