import { CloseIcon, IconButton, SelectInput, Table } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { AssetMappingValue } from '../model/MediaMappingValue';

const AttributeSelectContainer = styled.div`
    width: 300px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    attributes: Array<{
        code: string;
        label: string;
        mediaAssetAttributes: Array<{
            code: string;
            label: string;
            type: string;
            mediaType: string;
        }>;
        altTextAssetAttributes: Array<{
            code: string;
            label: string;
            type: string;
        }>;
    }>;
    value: AssetMappingValue;
    onChange: (value: AssetMappingValue) => void;
};

export const AssetAttributeItem = ({ attributes, value, onChange }: Props) => {
    const intl = useIntl();

    const handleAttributeChange = (attributeCode: string | null) => {
        if (attributeCode === null) {
            onChange({ attributeCode: null });
        } else {
            onChange({
                attributeCode,
                mediaAssetAttributeCode: null,
                altTextAssetAttributeCode: null,
            });
        }
    };

    const handleRemoveAttribute = () => {
        onChange({ attributeCode: null });
    };

    const handleMediaAssetAttributeChange = (
        attributeCode: string,
        mediaAssetAttributeCode: string | null,
    ) => {
        onChange({
            attributeCode,
            mediaAssetAttributeCode: mediaAssetAttributeCode,
            altTextAssetAttributeCode: value.altTextAssetAttributeCode,
        });
    };

    const getMediaAssetAttributes = (attributeCode: string) =>
        attributes.find((attribute) => attributeCode === attribute.code)?.mediaAssetAttributes ||
        [];

    const getMediaAssetAttribute = (attributeCode: string, mediaAssetAttributeCode: string) =>
        getMediaAssetAttributes(attributeCode).find(
            (assetAttribute) => assetAttribute.code === mediaAssetAttributeCode,
        ) || null;

    const handleAltTextAssetAttributeChange = (
        attributeCode: string,
        altTextAssetAttributeCode: string | null,
    ) => {
        onChange({
            attributeCode,
            mediaAssetAttributeCode: value.mediaAssetAttributeCode,
            altTextAssetAttributeCode: altTextAssetAttributeCode,
        });
    };

    const getAltTextAssetAttributes = (attributeCode: string) =>
        attributes.find((attribute) => attributeCode === attribute.code)?.altTextAssetAttributes ||
        [];

    return (
        <>
            <Table.Cell>
                <AttributeSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'lvAh1u',
                            defaultMessage: 'No asset collection attribute found',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'WB41Gj',
                            defaultMessage: 'Select an asset collection',
                        })}
                        value={value.attributeCode}
                        openLabel={''}
                        onChange={handleAttributeChange}
                        clearable={false}
                    >
                        {attributes.map((attribute) => (
                            <SelectInput.Option key={attribute.code} value={attribute.code}>
                                {attribute.label}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                </AttributeSelectContainer>
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode !== null && (
                    <AttributeSelectContainer>
                        <SelectInput
                            emptyResultLabel={intl.formatMessage({
                                id: '7d0gSj',
                                defaultMessage: 'No asset attribute found',
                            })}
                            placeholder={intl.formatMessage({
                                id: '4CLSQB',
                                defaultMessage: 'Select an asset attribute',
                            })}
                            value={value.mediaAssetAttributeCode ?? null}
                            openLabel={''}
                            onChange={(assetAttributeCode) =>
                                handleMediaAssetAttributeChange(
                                    value.attributeCode ?? '',
                                    assetAttributeCode,
                                )
                            }
                            clearable={false}
                        >
                            {getMediaAssetAttributes(value.attributeCode).map((assetAttribute) => (
                                <SelectInput.Option
                                    key={assetAttribute.code}
                                    value={assetAttribute.code}
                                >
                                    {assetAttribute.label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                    </AttributeSelectContainer>
                )}
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode &&
                    value.mediaAssetAttributeCode &&
                    getMediaAssetAttribute(value.attributeCode, value.mediaAssetAttributeCode)
                        ?.type}
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode &&
                    value.mediaAssetAttributeCode &&
                    getMediaAssetAttribute(value.attributeCode, value.mediaAssetAttributeCode)
                        ?.mediaType}
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode && value.mediaAssetAttributeCode && (
                    <AttributeSelectContainer>
                        <SelectInput
                            emptyResultLabel={intl.formatMessage({
                                id: '7d0gSj',
                                defaultMessage: 'No asset attribute found',
                            })}
                            placeholder={intl.formatMessage({
                                id: '4CLSQB',
                                defaultMessage: 'Select an asset attribute',
                            })}
                            value={
                                value.altTextAssetAttributeCode
                                    ? value.altTextAssetAttributeCode
                                    : null
                            }
                            openLabel={''}
                            onChange={(assetAttributeCode) =>
                                handleAltTextAssetAttributeChange(
                                    value.attributeCode ?? '',
                                    assetAttributeCode,
                                )
                            }
                            clearable={true}
                        >
                            {getAltTextAssetAttributes(value.attributeCode).map(
                                (assetAttribute) => (
                                    <SelectInput.Option
                                        key={assetAttribute.code}
                                        value={assetAttribute.code}
                                    >
                                        {assetAttribute.label}
                                    </SelectInput.Option>
                                ),
                            )}
                        </SelectInput>
                    </AttributeSelectContainer>
                )}
            </Table.Cell>
            <Table.Cell>
                <RemoveButtonContainer>
                    <IconButton
                        icon={<CloseIcon />}
                        title={intl.formatMessage({
                            id: 'S53Eji',
                            defaultMessage: 'Remove attribute',
                        })}
                        onClick={handleRemoveAttribute}
                        disabled={!value.attributeCode}
                        ghost='borderless'
                        level='tertiary'
                    />
                </RemoveButtonContainer>
            </Table.Cell>
        </>
    );
};
