import { Button, Field, Helper, Link, SectionTitle, TextAreaInput } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from '../store/StoreProvider';
import React from 'react';
import { helpCenterMetaobjectsMappingHowToMapMediaUrl } from '../../../util/config';
import { SpacerSmall } from '../../../component/Spacer';
import { useSaveMetaobjectMapping } from '../hook/useSaveMapping';

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

type Props = {
    maxMetaObjects: number;
};

export const MetaobjectForm = ({ maxMetaObjects }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { metaObjectMapping, mappingIsDirty } = useSelector((state) => state);

    const { saveMapping } = useSaveMetaobjectMapping();
    const handleSave = () => {
        saveMapping();
    };

    const isEmptyMapping = (mapping: string) => {
        return mapping === undefined || mapping.length === 0;
    };

    const isMaxMappingReached = (mapping: string) => {
        if (!isMappingValid(mapping)) {
            return false;
        }

        if (isEmptyMapping(mapping)) {
            return false;
        }

        const parsedMapping = JSON.parse(mapping);

        return parsedMapping.length > maxMetaObjects;
    };

    const isMappingValid = (mapping: string) => {
        try {
            if (isEmptyMapping(mapping)) {
                return true;
            }

            const parsedMapping = JSON.parse(mapping);

            return Array.isArray(parsedMapping);
        } catch (SyntaxError) {
            return false;
        }
    };

    const handleChange = (newValue: string) => {
        dispatch({
            type: 'metaObjectMapping/mapping/update',
            newValue: newValue,
        });
    };

    return (
        <>
            <SaveButtonContainer>
                {mappingIsDirty && (
                    <Helper inline level='warning'>
                        <FormattedMessage
                            id='2xq0/g'
                            defaultMessage='It seems that some modifications are unsaved'
                        />
                    </Helper>
                )}
                <Button level='primary' disabled={!mappingIsDirty} onClick={handleSave}>
                    <FormattedMessage id='jvo0vs' defaultMessage='Save' />
                </Button>
            </SaveButtonContainer>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='PtlOuA'
                        defaultMessage='Metaobjects - PIM Reference entities'
                    />
                </SectionTitle.Title>
            </SectionTitle>
            <Helper level='info'>
                <FormattedMessage
                    id='nlJHz0'
                    values={{
                        maxMetaObjects: maxMetaObjects,
                    }}
                    defaultMessage='Metaobjects synchronized from PIM reference entities enable you to share enriched information across multiple products, such as colors, materials, and manufacturing details.
                    You can map up to {maxMetaObjects} reference entities to sync with Shopify.'
                />{' '}
                <Link href={helpCenterMetaobjectsMappingHowToMapMediaUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>
            <SpacerSmall></SpacerSmall>
            <Field
                label={intl.formatMessage({
                    id: 'xQ/mJk',
                    defaultMessage: 'JSON Editor',
                })}
                fullWidth={true}
            >
                <TextAreaInput
                    onChange={(newValue: string) => handleChange(newValue)}
                    value={metaObjectMapping}
                    placeholder={intl.formatMessage({
                        defaultMessage:
                            'Please enter a valid mapping between PIM reference entities & Shopify Metaobjects',
                        id: '1sBGoe',
                    })}
                    invalid={!isMappingValid(metaObjectMapping)}
                />
            </Field>

            {isMaxMappingReached(metaObjectMapping) && (
                <Helper level='warning'>
                    <FormattedMessage
                        id='xyj4JO'
                        values={{
                            maxMetaObjects: maxMetaObjects,
                        }}
                        defaultMessage='You have reached the limit of {maxMetaObjects} metaobjects.'
                    />{' '}
                    <Link href={helpCenterMetaobjectsMappingHowToMapMediaUrl} target='_blank'>
                        <FormattedMessage
                            id='i/qJsQ'
                            defaultMessage='Please check the Help Center for more information.'
                        />
                    </Link>
                </Helper>
            )}
        </>
    );
};
