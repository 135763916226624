import { Immutable, produce } from 'immer';
import {
    MarketsPricingType,
    MarketsPricingMapping,
    ShopifyMarkets,
} from '../model/MarketsPricingModel';

export type State = Immutable<{
    shopifyMarkets: ShopifyMarkets;
    allowedShopifyMarkets: ShopifyMarkets;
    marketsPricingMapping: MarketsPricingMapping;
    pimLocales: { [code: string]: string };
    badRequestErrorMessage: string | null;
    mappingIsDirty: boolean;
}>;

export const initialState: State = {
    shopifyMarkets: [],
    allowedShopifyMarkets: [],
    marketsPricingMapping: [],
    pimLocales: {},
    badRequestErrorMessage: null,
    mappingIsDirty: false,
};

export type Action =
    | {
          type: 'shopifyMarkets/fetch/collections';
          shopifyMarkets: ShopifyMarkets;
      }
    | {
          type: 'marketsPricingMapping/fetch/collections';
          marketsPricingMapping: MarketsPricingMapping;
      }
    | {
          type: 'marketsPricingMapping/mapping/new';
          marketId: string | null;
          marketName: string | null;
          locale: string | null;
      }
    | {
          type: 'marketsPricingMapping/mapping/update';
          index: number;
          marketId: string | null;
          marketName: string | null;
          currency?: string | null;
          locale: string | null;
          errors?: { [fieldKey: string]: string[] };
      }
    | {
          type: 'marketsPricingMapping/mapping/remove';
          marketToRemove: string;
      }
    | {
          type: 'marketsPricingMapping/saved';
      }
    | {
          type: 'pimLocales/fetch';
          pimLocales: { [code: string]: string };
      }
    | {
          type: 'marketsPricingMapping/reject';
          errorMessage: string;
      };

export const reducer = produce<(draft: State, action: Action) => State>((draft, action) => {
    switch (action.type) {
        case 'shopifyMarkets/fetch/collections':
            draft.shopifyMarkets = action.shopifyMarkets;
            break;
        case 'marketsPricingMapping/fetch/collections':
            draft.marketsPricingMapping = action.marketsPricingMapping;
            break;
        case 'marketsPricingMapping/mapping/new':
            draft.marketsPricingMapping.push({
                marketId: action.marketId ?? null,
                marketName: action.marketName ?? null,
                locale: action.locale,
            });
            draft.mappingIsDirty =
                draft.marketsPricingMapping.find((marketPricing) => marketPricing.errors) ===
                undefined;
            break;
        case 'marketsPricingMapping/mapping/update':
            draft.marketsPricingMapping[action.index]!.marketId = action.marketId;
            draft.marketsPricingMapping[action.index]!.marketName = action.marketName;
            draft.marketsPricingMapping[action.index]!.currency = action.currency;
            draft.marketsPricingMapping[action.index]!.locale = action.locale;
            draft.marketsPricingMapping[action.index]!.errors = action.errors;
            draft.mappingIsDirty =
                draft.marketsPricingMapping.find((marketPricing) => marketPricing.errors) ===
                undefined;
            break;
        case 'marketsPricingMapping/mapping/remove':
            draft.marketsPricingMapping = draft.marketsPricingMapping.filter(
                (value: MarketsPricingType) => value.marketId !== action.marketToRemove,
            );
            draft.mappingIsDirty =
                draft.marketsPricingMapping.find((marketPricing) => marketPricing.errors) ===
                undefined;
            break;
        case 'marketsPricingMapping/saved':
            draft.badRequestErrorMessage = null;
            draft.mappingIsDirty = false;
            break;
        case 'pimLocales/fetch':
            draft.pimLocales = action.pimLocales;
            break;
        case 'marketsPricingMapping/reject':
            draft.badRequestErrorMessage = action.errorMessage;
            break;
    }

    draft.allowedShopifyMarkets = draft.shopifyMarkets?.filter(
        (allowedShopifyMarket) =>
            !draft.marketsPricingMapping.find(
                (mapping) => mapping.marketId === allowedShopifyMarket.id,
            ),
    );

    return draft;
});
