import { apiFetch } from '../../../util/apiFetch';

export const saveScheduledSyncConfig = ({
    currentStoreId,
    frequency,
    scope,
    filterAttribute,
    importSecondaryLocales,
    importMarketsPricing,
    importPreExistingProductsOnly,
    importAssociations,
    importMetafieldAssetCollections,
    publishProductsInChannels,
}: {
    currentStoreId: string;
    frequency: string;
    scope: string;
    filterAttribute: string | null;
    importSecondaryLocales: boolean;
    importMarketsPricing: boolean;
    importPreExistingProductsOnly: boolean;
    importAssociations: boolean;
    importMetafieldAssetCollections: boolean;
    publishProductsInChannels: boolean;
}) => {
    return apiFetch(`${currentStoreId}/scheduled-sync/save-scheduled-sync-config`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            frequency,
            scope,
            filterAttribute,
            importSecondaryLocales,
            importMarketsPricing,
            importPreExistingProductsOnly,
            importAssociations,
            importMetafieldAssetCollections,
            publishProductsInChannels,
        }),
    });
};
