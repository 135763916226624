import {
    AttributeLinkIcon,
    Collapse,
    Field,
    Helper,
    Link,
    SectionTitle,
    SelectInput,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { helpCenterPreExistingCatalog } from '../../../util/config';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useQuery } from 'react-query';
import { getPreExistingCatalogIdentifierValues } from '../api/getPreExistingCatalogIdentifierValues';
import { useCurrentStore } from '../../MultiStore';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { FlexLayout, FlexRow } from '../../../component/FlexLayout';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../util/InstabilityType';
import { connectorConfigDeepCopy } from '../store/reducer';

const defaultConnectorConfig: ConnectorConfig = {
    channel: null,
    locale: null,
    categoryCode: '',
    currency: null,
    preExistingCatalogMapping: {
        product_shopify_reference_field: null,
        product_pim_product_identifier: null,
        variant_shopify_reference_field: null,
        variant_pim_product_identifier: null,
    },
    publishingSettings: {
        salesChannels: [],
        b2bCatalogs: [],
    },
};

export const PreExistingCatalogMapping = () => {
    const dispatch = useDispatch();
    const { currentStoreId } = useCurrentStore();
    const intl = useIntl();
    const [
        isPreExistingCatalogMappingSectionCollapsed,
        setIsPreExistingCatalogMappingSectionCollapsed,
    ] = useState<boolean>(true);
    const bottomOfTheComponent = useRef<HTMLDivElement | null>(null);
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);
    const { preExistingCatalogIdentifierValues, connectorConfig, errors } = useSelector(
        (state) => ({
            preExistingCatalogIdentifierValues: state.preExistingCatalogIdentifierValues,
            connectorConfig: state.connectorConfigForm.data || defaultConnectorConfig,
            errors: state.connectorConfigForm.errors,
        }),
    );

    useEffect(() => {
        if (
            (null !== connectorConfig.preExistingCatalogMapping.product_shopify_reference_field &&
                null !==
                    connectorConfig.preExistingCatalogMapping.product_pim_product_identifier) ||
            (null !== connectorConfig.preExistingCatalogMapping.variant_shopify_reference_field &&
                null !== connectorConfig.preExistingCatalogMapping.variant_pim_product_identifier)
        ) {
            setIsPreExistingCatalogMappingSectionCollapsed(false);
        }
    }, [connectorConfig]);

    const { isFetching: isFetchingPreExistingCatalogIdentifierValues } = useQuery(
        'Configuration/getPreExistingCatalogIdentifierValues',
        () => getPreExistingCatalogIdentifierValues(currentStoreId),
        {
            onSuccess: (preExistingCatalogIdentifierValues) => {
                if (
                    preExistingCatalogIdentifierValues.error &&
                    preExistingCatalogIdentifierValues.error === InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);
                    return;
                }
                dispatch({
                    type: 'getPreExistingCatalogIdentifierValues/fulfilled',
                    preExistingCatalogIdentifierValues,
                });
            },
        },
    );

    const updateProductShopifyProductIdentifier = (
        productShopifyProductIdentifier: string | null,
    ) => {
        const newConnectorConfig = connectorConfigDeepCopy(connectorConfig);
        newConnectorConfig.preExistingCatalogMapping.product_shopify_reference_field =
            productShopifyProductIdentifier;

        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig: newConnectorConfig,
        });
    };

    const updateProductPimProductIdentifier = (productPimProductIdentifier: string | null) => {
        const newConnectorConfig = connectorConfigDeepCopy(connectorConfig);
        newConnectorConfig.preExistingCatalogMapping.product_pim_product_identifier =
            productPimProductIdentifier;

        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig: newConnectorConfig,
        });
    };

    const updateVariantShopifyProductIdentifier = (shopifyVariantIdentifier: string | null) => {
        const newConnectorConfig = connectorConfigDeepCopy(connectorConfig);
        newConnectorConfig.preExistingCatalogMapping.variant_shopify_reference_field =
            shopifyVariantIdentifier;

        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig: newConnectorConfig,
        });
    };

    const updateVariantPimProductIdentifier = (variantPimProductIdentifier: string | null) => {
        const newConnectorConfig = connectorConfigDeepCopy(connectorConfig);
        newConnectorConfig.preExistingCatalogMapping.variant_pim_product_identifier =
            variantPimProductIdentifier;

        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig: newConnectorConfig,
        });
    };

    const collapseOrExpandPreExistingCatalogMappingSection = () => {
        if (isPreExistingCatalogMappingSectionCollapsed) {
            setTimeout(() => {
                bottomOfTheComponent.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 200);
        }
        setIsPreExistingCatalogMappingSectionCollapsed(
            !isPreExistingCatalogMappingSectionCollapsed,
        );
    };

    return (
        <StyledWrapper>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        defaultMessage='Pre-existing catalog configuration (optional)'
                        id='wevssq'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>
            <PreExistingCatalogHelper level='info'>
                <FormattedMessage
                    defaultMessage='This section allows you to link pre-existing Shopify products or products with variants to your PIM products. Please note that these links are permanent.'
                    id='a8DeCE'
                />
                &nbsp;
                <Link href={helpCenterPreExistingCatalog} target='_blank'>
                    <FormattedMessage
                        defaultMessage='Check out our Help Center to consult prerequisites and recommendations.'
                        id='NLW6os'
                    />
                </Link>
            </PreExistingCatalogHelper>

            <StyledCollapse
                collapseButtonLabel='Collapse'
                label=''
                isOpen={!isPreExistingCatalogMappingSectionCollapsed}
                onCollapse={() => collapseOrExpandPreExistingCatalogMappingSection()}
            >
                <>
                    {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
                    <ProductMappingSectionTitle>
                        <StyledSectionTitle level='secondary'>
                            <FormattedMessage defaultMessage='Pre-existing products' id='maFHdh' />
                        </StyledSectionTitle>
                        <SectionTitle.Spacer />
                    </ProductMappingSectionTitle>

                    <StyledFlexLayout>
                        <FlexRow>
                            {!isFetchingPreExistingCatalogIdentifierValues ? (
                                <ShopifyProductIdentifier
                                    label={intl.formatMessage({
                                        defaultMessage: 'Shopify product identifier ',
                                        id: 'F/+J9N',
                                    })}
                                >
                                    <SelectInput
                                        placeholder={intl.formatMessage({
                                            defaultMessage: 'Select a Shopify product identifier',
                                            id: 'DYgQbO',
                                        })}
                                        openLabel=''
                                        value={
                                            connectorConfig.preExistingCatalogMapping
                                                ?.product_shopify_reference_field
                                        }
                                        onChange={(
                                            productShopifyProductIdentifier: string | null,
                                        ) =>
                                            updateProductShopifyProductIdentifier(
                                                productShopifyProductIdentifier,
                                            )
                                        }
                                        invalid={!!errors?.product_shopify_reference_field}
                                        emptyResultLabel={intl.formatMessage({
                                            defaultMessage: 'No Shopify product identifier found',
                                            id: 'pKpFYc',
                                        })}
                                    >
                                        {preExistingCatalogIdentifierValues?.shopifyReferenceFields.map(
                                            (label) => (
                                                <SelectInput.Option key={label} value={label}>
                                                    {label}
                                                </SelectInput.Option>
                                            ),
                                        )}
                                    </SelectInput>
                                    {undefined !== errors?.product_shopify_reference_field ? (
                                        <Helper level='error'>
                                            {errors?.product_shopify_reference_field}
                                        </Helper>
                                    ) : (
                                        <></>
                                    )}
                                </ShopifyProductIdentifier>
                            ) : (
                                <>
                                    <LabelSkeleton />
                                    <FieldSkeleton />
                                </>
                            )}
                        </FlexRow>

                        <StyledAttributeLinkIcon />

                        <FlexRow>
                            {!isFetchingPreExistingCatalogIdentifierValues ? (
                                <PimProductIdentifier
                                    label={intl.formatMessage({
                                        defaultMessage: 'PIM product identifier',
                                        id: 'Fafq+2',
                                    })}
                                >
                                    <SelectInput
                                        placeholder={intl.formatMessage({
                                            defaultMessage: 'Select a PIM product identifier',
                                            id: 'kKvIu7',
                                        })}
                                        openLabel=''
                                        value={
                                            connectorConfig.preExistingCatalogMapping
                                                ?.product_pim_product_identifier
                                        }
                                        onChange={(productPimProductIdentifier: string | null) =>
                                            updateProductPimProductIdentifier(
                                                productPimProductIdentifier,
                                            )
                                        }
                                        invalid={!!errors?.product_pim_product_identifier}
                                        emptyResultLabel={intl.formatMessage({
                                            defaultMessage: 'No PIM product identifier field found',
                                            id: 'knEX0l',
                                        })}
                                    >
                                        {preExistingCatalogIdentifierValues?.pimProductIdentifiers?.map(
                                            (pimProductIdentifier: {
                                                code: string;
                                                label: string;
                                            }) => (
                                                <SelectInput.Option
                                                    key={pimProductIdentifier.code}
                                                    value={pimProductIdentifier.code}
                                                >
                                                    {pimProductIdentifier.label}
                                                </SelectInput.Option>
                                            ),
                                        )}
                                    </SelectInput>
                                    <Helper level='info'>
                                        <FormattedMessage
                                            defaultMessage='Product identifier values must be unique to identify pre-existing products '
                                            id='6pAtea'
                                        />
                                    </Helper>
                                    {undefined !== errors?.product_pim_product_identifier ? (
                                        <Helper level='error'>
                                            {errors?.product_pim_product_identifier}
                                        </Helper>
                                    ) : (
                                        <></>
                                    )}
                                </PimProductIdentifier>
                            ) : (
                                <>
                                    <LabelSkeleton />
                                    <FieldSkeleton />
                                </>
                            )}
                        </FlexRow>
                    </StyledFlexLayout>

                    <SectionTitle>
                        <StyledSectionTitle level='secondary'>
                            <FormattedMessage
                                defaultMessage='Pre-existing products with variants'
                                id='bkijEs'
                            />
                        </StyledSectionTitle>
                        <SectionTitle.Spacer />
                    </SectionTitle>

                    <StyledFlexLayout>
                        <FlexRow>
                            {!isFetchingPreExistingCatalogIdentifierValues ? (
                                <ShopifyProductIdentifier
                                    label={intl.formatMessage({
                                        defaultMessage: 'Shopify variant identifier ',
                                        id: '7Ov3VI',
                                    })}
                                >
                                    <SelectInput
                                        placeholder={intl.formatMessage({
                                            defaultMessage: 'Select a Shopify variant identifier',
                                            id: '7C3npZ',
                                        })}
                                        openLabel=''
                                        value={
                                            connectorConfig.preExistingCatalogMapping
                                                ?.variant_shopify_reference_field
                                        }
                                        onChange={(shopifyVariantIdentifier: string | null) =>
                                            updateVariantShopifyProductIdentifier(
                                                shopifyVariantIdentifier,
                                            )
                                        }
                                        invalid={!!errors?.variant_shopify_reference_field}
                                        emptyResultLabel={intl.formatMessage({
                                            defaultMessage: 'No Shopify variant identifier found',
                                            id: '3OP1PJ',
                                        })}
                                    >
                                        {preExistingCatalogIdentifierValues?.shopifyReferenceFields.map(
                                            (label) => (
                                                <SelectInput.Option key={label} value={label}>
                                                    {label}
                                                </SelectInput.Option>
                                            ),
                                        )}
                                    </SelectInput>
                                    {undefined !== errors?.variant_shopify_reference_field ? (
                                        <Helper level='error'>
                                            {errors?.variant_shopify_reference_field}
                                        </Helper>
                                    ) : (
                                        <></>
                                    )}
                                </ShopifyProductIdentifier>
                            ) : (
                                <>
                                    <LabelSkeleton />
                                    <FieldSkeleton />
                                </>
                            )}
                        </FlexRow>

                        <StyledAttributeLinkIcon />

                        <FlexRow>
                            {!isFetchingPreExistingCatalogIdentifierValues ? (
                                <PimProductIdentifier
                                    label={intl.formatMessage({
                                        defaultMessage: 'PIM variant identifier',
                                        id: 'KkFpST',
                                    })}
                                >
                                    <SelectInput
                                        placeholder={intl.formatMessage({
                                            defaultMessage: 'Select a PIM variant identifier',
                                            id: 'VXWO9w',
                                        })}
                                        openLabel=''
                                        value={
                                            connectorConfig.preExistingCatalogMapping
                                                ?.variant_pim_product_identifier
                                        }
                                        onChange={(variantPimProductIdentifier: string | null) =>
                                            updateVariantPimProductIdentifier(
                                                variantPimProductIdentifier,
                                            )
                                        }
                                        invalid={!!errors?.variant_pim_product_identifier}
                                        emptyResultLabel={intl.formatMessage({
                                            defaultMessage: 'No PIM variant identifier field found',
                                            id: '8MSi7K',
                                        })}
                                    >
                                        {preExistingCatalogIdentifierValues?.pimProductIdentifiers?.map(
                                            (pimProductIdentifier: {
                                                code: string;
                                                label: string;
                                            }) => (
                                                <SelectInput.Option
                                                    key={pimProductIdentifier.code}
                                                    value={pimProductIdentifier.code}
                                                >
                                                    {pimProductIdentifier.label}
                                                </SelectInput.Option>
                                            ),
                                        )}
                                    </SelectInput>
                                    <Helper level='info'>
                                        <FormattedMessage
                                            defaultMessage='Variant identifier values must be unique to identify pre-existing products with variants'
                                            id='OCKLq3'
                                        />
                                    </Helper>
                                    {undefined !== errors?.variant_pim_product_identifier ? (
                                        <Helper level='error'>
                                            {errors?.variant_pim_product_identifier}
                                        </Helper>
                                    ) : (
                                        <></>
                                    )}
                                </PimProductIdentifier>
                            ) : (
                                <>
                                    <LabelSkeleton />
                                    <FieldSkeleton />
                                </>
                            )}
                        </FlexRow>

                        <div ref={bottomOfTheComponent}></div>
                    </StyledFlexLayout>
                </>
            </StyledCollapse>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    min-height: 120px;
`;

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    margin-bottom: 10px;
    width: 25%;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;

const StyledSectionTitle = styled(SectionTitle.Title)`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 20px;
`;

const ShopifyProductIdentifier = styled(Field)`
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 400px;
`;

const PimProductIdentifier = styled(Field)`
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 400px;
`;

const StyledFlexLayout = styled(FlexLayout)`
    justify-content: start;
    gap: 8px;
`;

const StyledAttributeLinkIcon = styled(AttributeLinkIcon)`
    margin-top: 51px;
`;

const StyledCollapse = styled(Collapse)`
    border: none;
    margin-top: -110px;
    position: relative;
`;

const ProductMappingSectionTitle = styled(SectionTitle)`
    margin-top: 80px;
`;

const PreExistingCatalogHelper = styled(Helper)`
    z-index: 1;
`;
