import { useQuery } from 'react-query';
import { apiFetch } from '../../../../util/apiFetch';
import { PublishingChannels } from '../model/PublishingChannels';
import { PublishingChannel } from '../model/PublishingChannel';
import * as InstabilityType from '../../../../util/InstabilityType';

type Error = string | null;
type Result = {
    isLoading: boolean;
    isError: boolean;
    data: PublishingChannel[] | undefined;
    error: Error;
};

export const usePublishingChannels = (
    storeId: string,
    setShopifyHasInstability: (newValue: boolean) => void,
): Result => {
    return useQuery<PublishingChannel[], Error, PublishingChannel[]>(
        'publishingChannels',
        async (): Promise<PublishingChannel[]> => {
            return await apiFetch<PublishingChannels>(
                `${storeId}/configuration/retrieve-publishing-channels`,
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                },
            ).then((result) => {
                if (result.error === InstabilityType.shopify_instability) {
                    setShopifyHasInstability(true);
                }
                return result.publishingChannels;
            });
        },
    );
};
