import { Immutable } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { CollectionEmptyAttributeMapping } from '../../model/CollectionEmptyAttributeMapping';
import { MappingStatus } from '../../model/MappingStatus';
import { SingleEmptyAttributeMapping } from '../../model/SingleEmptyAttributeMapping';
import { State } from '../store/reducer';

export const selectAttributeMapping = (shopifyAttributeCode: string) => {
    return (
        state: State,
    ): Immutable<
        AttributeMapping | SingleEmptyAttributeMapping | CollectionEmptyAttributeMapping
    > => {
        const shopifyAttribute = state.shopifyAttributes[shopifyAttributeCode];
        if (!shopifyAttribute) {
            throw new Error();
        }

        const attributeMapping = state.mapping.get(shopifyAttributeCode);

        if (!attributeMapping) {
            if (shopifyAttribute.collection) {
                return {
                    shopifyAttributeCode,
                    hasCollectionOfAttribute: true,
                    hasAttributePerFamily: false,
                    pimAttributeCodes: [],
                    status: MappingStatus.None,
                };
            }

            return {
                shopifyAttributeCode,
                hasCollectionOfAttribute: false,
                hasAttributePerFamily: false,
                pimAttributeCode: null,
                status: MappingStatus.None,
            };
        }

        return attributeMapping;
    };
};
