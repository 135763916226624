import { apiFetch } from '../../../../util/apiFetch';
import { ShopifyMarketType } from '../model/MarketsPricingModel';
type Result = {
    markets?: ShopifyMarketType[];
    error?:
        | string
        | {
              code: number;
              message: string;
          };
};
export const getShopifyMarketsQueryKey = 'getShopifyMarkets';

export const getShopifyMarkets = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/get-markets`).then((result) => result);
