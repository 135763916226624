import { apiFetch } from '../../../../util/apiFetch';
import { SynchronizationReportLogsModel } from '../model/SynchronizationReportLogsModel';

export const getSynchronizationReportLogs = (
    storeId: string,
    synchronizationId: string,
    logCode: string,
) =>
    apiFetch<SynchronizationReportLogsModel>(
        `${storeId}/synchronization/${synchronizationId}/retrieve-synchronization-report/${logCode}/report-log`,
    ).then((result) => {
        return result.synchronizationReportLogs;
    });
