import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'akeneo-design-system';
import { MediaSource } from '../../../model/MediaSource';
import { useMediaMapping } from '../hook/useMediaMapping';

type Props = {
    attributeMapping: string;
};

export const MediaMappingVariantState = ({ attributeMapping }: Props) => {
    const { mapping } = useMediaMapping();

    if (mapping.attributeCode !== null) {
        return (
            <Badge level='primary'>
                {mapping.source === MediaSource.Images && (
                    <FormattedMessage id='TgW9YV' defaultMessage='Image attribute mapped' />
                )}
                {mapping.source === MediaSource.Assets && (
                    <FormattedMessage id='455vYa' defaultMessage='Asset attribute mapped' />
                )}
            </Badge>
        );
    }

    return (
        <Badge level='tertiary'>
            <FormattedMessage id='y+FM2U' defaultMessage='Not complete' />
        </Badge>
    );
};
