import { AttributesIllustration, Link, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ManageMetafieldsButton } from './ManageMetafieldsButton';
import styled from 'styled-components';
import { helpCenterMetafieldsMappingUrl } from '../../../../util/config';

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 15px;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
`;

const linkStyle = {
    marginBottom: '10px',
};

export const NoMetafieldsFromPimAttributes = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'IGIaI+',
        defaultMessage: 'No metafields from PIM attributes for the moment',
    });

    return (
        <>
            <Placeholder illustration={<AttributesIllustration />} size='large' title={title} />
            <MessageContainer>
                <FormattedMessage
                    id='iXyO5o'
                    defaultMessage='To map a metafield, use "Select metafields" button.'
                />
                <Link href={helpCenterMetafieldsMappingUrl} target='_blank' style={linkStyle}>
                    <FormattedMessage
                        id='43uZEt'
                        defaultMessage='Visit our Help Center for more information'
                    />
                </Link>

                <ManageMetafieldsButton noMappedMetafields={true} />
            </MessageContainer>
        </>
    );
};
