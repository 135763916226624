import { Dispatch } from 'react';
import { useQuery } from 'react-query';
import { getConnectorConfig } from '../api/getConnectorConfig';
import { Action, connectorConfigDeepCopy } from '../store/reducer';
import { useCurrentStore } from '../../MultiStore';

export const fetchConfigurationQueryKey = 'Configuration/fetchConfiguration';

export const useFetchConfiguration = (dispatch: Dispatch<Action>) => {
    const { currentStoreId } = useCurrentStore();

    return useQuery(fetchConfigurationQueryKey, () => getConnectorConfig(currentStoreId), {
        onSuccess: (connectorConfig) => {
            const payload = {
                categoryCode: connectorConfig?.categoryCode || '',
                locale: connectorConfig?.locale || '',
                channel: connectorConfig?.channel || '',
                currency: connectorConfig?.currency || '',
                preExistingCatalogMapping: {
                    product_shopify_reference_field:
                        connectorConfig?.preExistingCatalogMapping?.product_mapping
                            .shopify_reference_field || null,
                    product_pim_product_identifier:
                        connectorConfig?.preExistingCatalogMapping?.product_mapping
                            .pim_product_identifier || null,
                    variant_shopify_reference_field:
                        connectorConfig?.preExistingCatalogMapping?.variant_mapping
                            .shopify_reference_field || null,
                    variant_pim_product_identifier:
                        connectorConfig?.preExistingCatalogMapping?.variant_mapping
                            .pim_product_identifier || null,
                },
                publishingSettings: {
                    salesChannels: connectorConfig?.publishingSettings?.sales_channels ?? [],
                    b2bCatalogs: connectorConfig?.publishingSettings?.b2b_catalogs ?? [],
                },
            };

            dispatch({
                type: 'fetchConfiguration/fulfilled',
                payload: {
                    connectorConfig: connectorConfigDeepCopy(payload),
                },
            });
        },
    });
};
