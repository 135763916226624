import { apiFetch } from '../../../../util/apiFetch';
import { ShopifyMetafieldDefinition } from '../model/MetafieldAttributeModel';

export type AttributeMappingByStore = {
    attributeMapping: string[];
    storeId: string;
};

export type ShopifyMetafieldDefinitionMapping = {
    [p: string]: ShopifyMetafieldDefinition | undefined;
};

export const saveMetafieldAttributeMapping = (
    attributeMappingByStore: AttributeMappingByStore,
    existingProductDefinitionMapping: ShopifyMetafieldDefinitionMapping,
    existingVariantDefinitionMapping: ShopifyMetafieldDefinitionMapping,
) => {
    return apiFetch(
        `${attributeMappingByStore.storeId}/metafield/save-metafield-attribute-mapping`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attributeMapping: attributeMappingByStore.attributeMapping,
                existingProductDefinitionMapping: existingProductDefinitionMapping,
                existingVariantDefinitionMapping: existingVariantDefinitionMapping,
            }),
        },
    );
};
