import { AllowedPimAttributeSelect } from '../component/AllowedPimAttributeSelect';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { PimAttributeErrors } from '../../model/PimAttributeErrors';

type Props = {
    shopifyAttribute: ShopifyAttribute;
    pimAttributeErrors: PimAttributeErrors | null;
};

export const PimAttributeSelector = ({ shopifyAttribute, pimAttributeErrors }: Props) => {
    const dispatch = useDispatch();

    const attributeMapping = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(shopifyAttribute.code)(state);
        if (attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        return attributeMapping;
    });

    const handleChange = (pimAttributeCode: string | null) =>
        dispatch({
            type: 'variantMapping/PimAttributeCodeChanged',
            shopifyAttributeCode: shopifyAttribute.code,
            pimAttributeCode,
        });

    return (
        <AllowedPimAttributeSelect
            shopifyAttribute={shopifyAttribute}
            selectedPimAttributeCode={
                attributeMapping.hasAttributePerFamily ? null : attributeMapping.pimAttributeCode
            }
            disabled={attributeMapping.hasAttributePerFamily}
            onChange={handleChange}
            pimAttributeErrors={pimAttributeErrors}
        />
    );
};
