import React, { useState } from 'react';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { useCurrentStore } from '../../MultiStore';
import { useQuery } from 'react-query';
import { getSecondaryLocales, getSecondaryLocalesQueryKey } from './api/getMultiLocales';
import { getSecondaryLocalesMapped } from './api/getMultiLocalesMapped';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../util/InstabilityType';

type Props = {
    children: React.ReactNode;
};

export const SecondaryLocalesProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { currentStoreId } = useCurrentStore();

    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);
    const [shopifyHasInstability, setShopifyHasInstability] = useState<boolean>(false);

    useQuery(
        [currentStoreId, getSecondaryLocalesQueryKey],
        () =>
            Promise.all([
                getSecondaryLocales(currentStoreId),
                getSecondaryLocalesMapped(currentStoreId),
            ]),
        {
            onSuccess: ([secondaryLocales, secondaryLocalesMapped]) => {
                if (
                    secondaryLocales.error &&
                    secondaryLocales.error === InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);
                    return;
                }
                if (
                    secondaryLocales.error &&
                    secondaryLocales.error === InstabilityType.shopify_instability
                ) {
                    setShopifyHasInstability(true);
                    return;
                }
                dispatch({
                    type: 'secondaryLocales/fetch/collections',
                    activatedShopifyLanguages: secondaryLocales.activatedShopifyLanguages,
                    pimEnabledLocales: secondaryLocales.pimEnabledLocales,
                });

                dispatch({
                    type: 'secondaryLocalesMapped/fetch',
                    secondaryLocalesMapped: secondaryLocalesMapped,
                });
            },
        },
    );

    return (
        <>
            {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
            {shopifyHasInstability && <InstabilityStickyHelper source='SHOPIFY' />}

            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
            </StateContext.Provider>
        </>
    );
};
