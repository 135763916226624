import React from 'react';
import { ReleaseNote } from '../model/Announcement';
import styled from 'styled-components';
import { getColor, Tag, Tags } from 'akeneo-design-system';
import { AnnouncementHeader } from './AnnouncementHeader';
import { ReadMore } from './ReadMore';
import { FormattedMessage } from 'react-intl';

type Props = {
    data: ReleaseNote;
};

const ReleaseNotesItem = ({ data }: Props) => {
    return (
        <Container>
            <AnnouncementHeader date={data.date} title={data.title}>
                <Tags>
                    <Tag tint='dark_blue'>
                        <FormattedMessage id='IeGkYp' defaultMessage='Release note' />
                    </Tag>
                </Tags>
            </AnnouncementHeader>
            <Features>
                {data.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </Features>
            {data.link !== undefined && <ReadMore link={data.link} />}
        </Container>
    );
};

const Container = styled.div`
    border-bottom: 1px solid ${getColor('grey', 80)};
    margin-bottom: 20px;
    padding-bottom: 20px;
`;

const Features = styled.ul`
    margin: 0 0 0 7px;
    padding: 0;
    list-style-type: none;
    color: ${getColor('grey', 140)};

    li:before {
        content: '-';
        position: absolute;
        margin-left: -7px;
    }
`;

export { ReleaseNotesItem };
