import { Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '../store/StoreProvider';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getPimAttributes } from '../../../api/getPimAttributes';
import { useCurrentStore } from '../../../../../MultiStore';
import { Loading } from '../../../../../../component';
import { ImageAttributeItem } from './ImageAttributeItem';

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

type Props = {
    maxAttribute: number;
};

export const ImageAttributeList = ({ maxAttribute }: Props) => {
    const { currentStoreId } = useCurrentStore();
    const { mediaMapping, mediaAttributes } = useSelector((state) => state);
    const dispatch = useDispatch();

    const { isLoading } = useQuery('getPimAttributes', () => getPimAttributes(currentStoreId), {
        onSuccess: (result) => {
            const allowedAttributes = Object.values(result.pimAttributes)
                .filter(({ type }) => type === 'pim_catalog_image')
                .sort(({ label: a }, { label: b }) => {
                    a = a.toUpperCase();
                    b = b.toUpperCase();
                    return a < b ? -1 : a > b ? 1 : 0;
                });
            dispatch({
                type: 'mediaMapping/fetch/attributes',
                mediaAttributes: allowedAttributes,
            });
        },
    });

    const handleSort = (newIndices: number[]): void => {
        dispatch({
            type: 'mediaMapping/mapping/sort',
            newIndices: newIndices,
        });
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Table
                        isDragAndDroppable={true}
                        onReorder={(newIndices) => {
                            handleSort(newIndices);
                        }}
                    >
                        <Table.Header>
                            <Table.HeaderCell>
                                <FormattedMessage id='OsU2Fs' defaultMessage='Attribute' />
                            </Table.HeaderCell>
                            <Space />
                        </Table.Header>
                        <Table.Body>
                            {mediaMapping.map((imageMapped) => {
                                const imageAttribute = mediaAttributes.get(
                                    imageMapped.attribute_code,
                                );
                                if (imageAttribute !== undefined) {
                                    return (
                                        <Table.Row key={imageMapped.attribute_code}>
                                            <ImageAttributeItem imageAttribute={imageAttribute} />
                                        </Table.Row>
                                    );
                                }
                                return <></>;
                            })}
                            {mediaMapping.length !== maxAttribute && (
                                <Table.Row key={'newAttribute'}>
                                    <ImageAttributeItem
                                        key={mediaMapping.length}
                                        imageAttribute={null}
                                    />
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    {mediaMapping.length === maxAttribute && (
                        <Helper level='warning'>
                            <FormattedMessage
                                id='wAsRDR'
                                values={{
                                    maxImagesAttributes: maxAttribute,
                                }}
                                defaultMessage='You have reached the limit of {maxImagesAttributes} images per product. '
                            />
                        </Helper>
                    )}
                </>
            )}
        </>
    );
};
