import { Navigate, Route, Routes } from 'react-router-dom';
import { ValidConfigurationGuard } from '../../Configuration';
import { RunningSynchronizationGuard } from '../../Synchronization';
import { DisabledMapping } from '../component/DisabledMapping';
import { InvalidConfiguration } from '../component/InvalidConfiguration';
import { VariantNativeFields } from '../NativeFields/VariantNativeFields';

export const VariantMapping = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(isConfigInvalid) => (
                    <InvalidConfiguration isConfigInvalid={isConfigInvalid} />
                )}
            >
                <Routes>
                    <Route path='native-field/*' element={<VariantNativeFields />} />
                    <Route path='*' element={<Navigate to='native-field' replace />} />
                </Routes>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
