import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Green = styled.span`
    color: ${({ theme }) => theme.color.green140};
`;
const Red = styled.span`
    color: ${({ theme }) => theme.color.red140};
`;
const Grey = styled.span`
    color: ${({ theme }) => theme.color.grey140};
`;

type Props = {
    detailedReport: {
        created?: number;
        updated?: number;
        errors?: number;
        processed?: number;
    };
};

export const DetailedReportCounter = ({ detailedReport }: Props) => {
    return (
        <>
            {detailedReport?.created !== undefined && (
                <>
                    <FormattedMessage id='IyAJ1t' defaultMessage='Created: ' />
                    {detailedReport.created > 0 ? (
                        <Green>{detailedReport.created}</Green>
                    ) : (
                        <Grey>{detailedReport.created}</Grey>
                    )}
                </>
            )}
            {detailedReport?.updated !== undefined && (
                <>
                    <FormattedMessage id='Wx4WiM' defaultMessage='Updated: ' />
                    {detailedReport.updated > 0 ? (
                        <Green>{detailedReport.updated}</Green>
                    ) : (
                        <Grey>{detailedReport.updated}</Grey>
                    )}
                </>
            )}
            {detailedReport?.processed !== undefined && (
                <>
                    <FormattedMessage id='8/SqWD' defaultMessage='Processed: ' />
                    {detailedReport.processed > 0 ? (
                        <Green>{detailedReport.processed}</Green>
                    ) : (
                        <Grey>{detailedReport.processed}</Grey>
                    )}
                </>
            )}
            {detailedReport?.errors !== undefined && (
                <>
                    <FormattedMessage id='Rjoa9F' defaultMessage='Errors: ' />
                    {detailedReport.errors > 0 ? (
                        <Red>{detailedReport.errors}</Red>
                    ) : (
                        <Grey>{detailedReport.errors}</Grey>
                    )}
                </>
            )}
        </>
    );
};
