import React from 'react';
import { useQuery } from 'react-query';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { useCurrentStore } from '../MultiStore';
import { Loading } from '../../component';
import { getMetaobjectMapping } from './api/getMetaobjectMapping';

type Props = {
    children: React.ReactNode;
};

export const MetaobjectsProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { currentStoreId } = useCurrentStore();

    const { isFetching } = useQuery(
        [currentStoreId, 'getMetaobjectMapping'],
        () => getMetaobjectMapping(currentStoreId),
        {
            onSuccess: (result) => {
                dispatch({
                    type: 'metaObjectMapping/fetch/sourceAndMapping',
                    metaObjectMapping: JSON.stringify(result, null, 2),
                });
            },
        },
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};
