import { Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    children: React.ReactNode;
};

export const FamilyMappingList = ({ children }: Props) => {
    return (
        <Table>
            <Table.Header>
                <Table.HeaderCell>
                    <FormattedMessage id='6e2e3X' defaultMessage='PIM family' />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage id='h4QvQI' defaultMessage='PIM attribute' />
                </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                <>{children}</>
            </Table.Body>
        </Table>
    );
};
