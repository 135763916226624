import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import { useState } from 'react';
import {
    AssociationMappingByStore,
    saveMetafieldAssociationMapping,
} from './api/saveMetafieldAssociationMapping';
import { BadRequestError } from '../../../util/apiFetch';
import { useNavigate } from 'react-router';

export const useSaveMetafieldAssociationMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const saveMapping = async (associationMapping: AssociationMappingByStore) => {
        setIsSaving(true);

        try {
            await saveMetafieldAssociationMapping(associationMapping);
            addToast(
                intl.formatMessage({
                    id: '3bvWDQ',
                    defaultMessage: 'Association selection successfully updated.',
                }),
                'success',
            );
            navigate('..');
        } catch (error) {
            if (error instanceof BadRequestError) {
                addToast(
                    intl.formatMessage(
                        {
                            id: 'PR2F1h',
                            defaultMessage: '{message}',
                        },
                        {
                            message: error?.data.error.message,
                        },
                    ),
                    'error',
                );
            } else {
                addToast(
                    intl.formatMessage({
                        id: 'rWJqOb',
                        defaultMessage:
                            'An unexpected error occurred while saving the association mapping.',
                    }),
                    'error',
                );
            }
        }

        setIsSaving(false);
    };
    return { saveMapping, isSaving };
};
