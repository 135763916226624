import { MediaSource } from '../../../model/MediaSource';
import { MediaMapping } from '../model/MediaMapping';
import { apiFetch } from '../../../../../../util/apiFetch';

export const saveVariantMediaMapping = async ({
    storeId,
    mapping,
}: {
    storeId: string;
    mapping: MediaMapping;
}) => {
    let data = {};

    if (mapping.attributeCode !== null) {
        if (mapping.source === MediaSource.Images) {
            data = [
                {
                    attribute_code: mapping.attributeCode,
                },
            ];
        } else if (mapping.source === MediaSource.Assets) {
            data = [
                {
                    attribute_code: mapping.attributeCode,
                    asset_attribute_code: mapping.mediaAssetAttributeCode,
                    asset_attribute_code_alt_text: mapping.altTextAssetAttributeCode,
                },
            ];
        }
    }

    await apiFetch(`${storeId}/mapping/save-variant-media-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            source: mapping.source,
            mapping: data,
        }),
    });
};
