import { apiFetch } from '../../../util/apiFetch';

export const stopSynchronization = ({
    id,
    currentStoreId,
}: {
    id: string;
    currentStoreId: string;
}) => {
    return apiFetch(`${currentStoreId}/synchronization/stop-synchronization/${id}`, {
        method: 'POST',
    });
};
