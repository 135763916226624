import { apiFetch } from '../../../util/apiFetch';
import { FilterPimAttribute } from '../model/FilterPimAttribute';

type Result = {
    pimAttributes: Array<{
        label: string;
        code: string;
    }>;
};

export const fetchFilterPimAttributes = (currentStoreId: string): Promise<FilterPimAttribute[]> =>
    apiFetch<Result>(`${currentStoreId}/synchronization/retrieve-filter-pim-attributes`).then(
        (result) =>
            result.pimAttributes.map((data) => ({
                label: data.label,
                code: data.code,
            })),
    );
