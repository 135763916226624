import {
    Button,
    DeleteFamilyVariantIllustration,
    getColor,
    Helper,
    Link,
    Modal,
    Table,
} from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { helpCenterMetafieldsMappingUrl } from '../../../../util/config';

type Props = {
    closeModal: () => void;
    metafieldDeletionList: {
        label: string;
        reasonBy: {
            productDefinition: string;
            variantDefinition: string;
        };
    }[];
    save: () => void;
};

const RightPanelContainer = styled.div`
    text-align: left;
    max-width: 700px;
`;

const TitleContainer = styled.div`
    line-height: 1.8rem;
`;

const SubTitle = styled.div`
    color: ${getColor('grey140')};
`;

const HelperContainer = styled.div`
    margin-top: 25px;
`;

const MetafieldDeletionListContainer = styled.div`
    margin-top: 17px;
    overflow-y: auto;
    max-height: 400px;
    -ms-overflow-style: none;
    td {
        border-bottom: none;
        color: ${getColor('grey120')};
    }
`;

const MetafieldDeselectionModal = ({ closeModal, metafieldDeletionList, save }: Props) => {
    return (
        <Modal
            onClose={closeModal}
            closeTitle={'Cancel'}
            illustration={<DeleteFamilyVariantIllustration />}
        >
            <>
                <RightPanelContainer>
                    <TitleContainer>
                        <Modal.Title>
                            <FormattedMessage id='mxdcDv' defaultMessage='Confirm major changes' />
                        </Modal.Title>
                        <SubTitle>
                            <FormattedMessage
                                id='tmYGy1'
                                defaultMessage='By saving the new selection, these following fields will be removed.'
                            />
                        </SubTitle>
                    </TitleContainer>

                    <HelperContainer>
                        <Helper level='warning'>
                            <FormattedMessage
                                id='lK+7LN'
                                defaultMessage='You have made changes to the mapping of your PIM attributes as metafields. Please note that these changes will impact metafields already present in Shopify the next time you synchronize.'
                            />
                            &nbsp;
                            <Link href={helpCenterMetafieldsMappingUrl} target='_blank'>
                                <FormattedMessage
                                    id='9R426o'
                                    defaultMessage='Please check our Help Center for more information.'
                                />
                            </Link>
                        </Helper>
                    </HelperContainer>

                    <MetafieldDeletionListContainer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    <FormattedMessage id='h4QvQI' defaultMessage='PIM attribute' />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage id='y1Qr81' defaultMessage='Level' />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage id='W2JBdp' defaultMessage='Impact' />
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {metafieldDeletionList.map((metafield) => (
                                    <Table.Row key={metafield.label}>
                                        <Table.Cell>{metafield.label}</Table.Cell>
                                        <Table.Cell>
                                            {metafield.reasonBy.productDefinition && (
                                                <FormattedMessage
                                                    id='x/ZVlU'
                                                    defaultMessage='Product'
                                                />
                                            )}
                                            {metafield.reasonBy.productDefinition &&
                                                metafield.reasonBy.variantDefinition && <br />}
                                            {metafield.reasonBy.variantDefinition && (
                                                <FormattedMessage
                                                    id='OK5+Fh'
                                                    defaultMessage='Variant'
                                                />
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {metafield.reasonBy.productDefinition ===
                                            metafield.reasonBy.variantDefinition ? (
                                                metafield.reasonBy.productDefinition
                                            ) : (
                                                <>
                                                    {metafield.reasonBy.productDefinition}
                                                    {metafield.reasonBy.productDefinition &&
                                                        metafield.reasonBy.variantDefinition && (
                                                            <br />
                                                        )}
                                                    {metafield.reasonBy.variantDefinition}
                                                </>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </MetafieldDeletionListContainer>
                </RightPanelContainer>
            </>

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={save}>
                    <FormattedMessage id='b5nahW' defaultMessage='Save selection' />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { MetafieldDeselectionModal };
