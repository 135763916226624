import { Button, Helper } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from '../store/StoreProvider';
import { FamilyMappingItem } from '../../Component/FamilyMapping/FamilyMappingItem';
import { FamilyMappingList } from '../../Component/FamilyMapping/FamilyMappingList';
import { MappedFamilyProgressBar } from './component/MappedFamilyProgressBar';
import { FamilyPimAttributeCollectionSelector } from './FamilyPimAttributeCollectionSelector';
import { FamilyPimAttributeSelector } from './FamilyPimAttributeSelector';

const Container = styled.div`
    background: white;
    left: 0;
    padding: 40px 80px;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 80px;
    top: 40px;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.color.brand100};
    font-size: ${({ theme }) => theme.fontSize.bigger};
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
`;

const AttributeLabel = styled.div`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.title};
    padding-top: 1rem;
`;

export const ManageVariantFamilyMapping = () => {
    const { shopifyAttributeCode } = useParams<{
        shopifyAttributeCode: string;
    }>();

    const intl = useIntl();
    const navigate = useNavigate();

    const shopifyAttribute = useSelector((state) => {
        const shopifyAttribute = state.shopifyAttributes[shopifyAttributeCode!];
        if (!shopifyAttribute) {
            throw new Error(
                intl.formatMessage(
                    {
                        id: '/UYIm4',
                        defaultMessage: 'Shopify attribute "{shopifyAttributeCode}" doesn\'t exist',
                    },
                    { shopifyAttributeCode },
                ),
            );
        }

        return shopifyAttribute;
    });

    const pimFamilies = useSelector((state) => state.pimFamilies);

    return (
        <Container>
            <ButtonContainer>
                <Button level='tertiary' ghost onClick={() => navigate('..')}>
                    <FormattedMessage id='S27je2' defaultMessage='Return to the mapping' />
                </Button>
            </ButtonContainer>

            <Title>
                <FormattedMessage id='SUOxRz' defaultMessage='Manage family mapping' />
                <br />
                <AttributeLabel>{shopifyAttribute.label}</AttributeLabel>
            </Title>

            <MappedFamilyProgressBar shopifyAttributeCode={shopifyAttributeCode!} />

            {['NAME', 'PRICE', 'WEIGHT', 'TYPE'].includes(shopifyAttribute.code) && (
                <>
                    <br />
                    <Helper level='warning'>
                        <FormattedMessage
                            id='xa6lPZ'
                            defaultMessage='This attribute is required, please make sure you mapped all your families before you launch an import.'
                        />
                    </Helper>
                </>
            )}

            <br />

            <FamilyMappingList>
                {Object.values(pimFamilies).map((pimFamily) => (
                    <FamilyMappingItem key={pimFamily.code} pimFamilyLabel={pimFamily.label}>
                        {!shopifyAttribute.collection && (
                            <FamilyPimAttributeSelector
                                shopifyAttribute={shopifyAttribute}
                                pimFamilyCode={pimFamily.code}
                            />
                        )}
                        {shopifyAttribute.collection && (
                            <FamilyPimAttributeCollectionSelector
                                shopifyAttribute={shopifyAttribute}
                                pimFamilyCode={pimFamily.code}
                            />
                        )}
                    </FamilyMappingItem>
                ))}
            </FamilyMappingList>
        </Container>
    );
};
