import { Route, Routes } from 'react-router';
import { ProductVariantMappingProvider } from './ProductVariant/ProductVariantMappingProvider';
import { ManageVariantMapping } from './ManageVariantMapping';
import { ManageVariantFamilyMapping } from './ProductVariant/ProductVariantMappingPerFamily/ManageVariantFamilyMapping';
import { MediaMapping } from './Product/MediaMapping/MediaMapping';
import { MediaMappingProvider } from './Product/MediaMapping/MediaMappingProvider';
import { VariantMediaMapping } from './ProductVariant/MediaMapping/VariantMediaMapping';
import { MediaMappingScope } from './model/MediaMappingScope';

export const VariantNativeFields = () => (
    <ProductVariantMappingProvider>
        <MediaMappingProvider mediaMappingScope={MediaMappingScope.ProductModel}>
            <Routes>
                <Route path='/' element={<ManageVariantMapping />} />
                <Route path=':shopifyAttributeCode' element={<ManageVariantFamilyMapping />} />
                <Route path='media-mapping' element={<MediaMapping />} />
                <Route path='variant-media-mapping' element={<VariantMediaMapping />} />
            </Routes>
        </MediaMappingProvider>
    </ProductVariantMappingProvider>
);
