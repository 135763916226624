import { Helper, SectionTitle, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

import React, { useState } from 'react';
import { NoMetafieldsFromPimAssociations } from './NoMetafieldsFromPimAssociations';
import { useCurrentStore } from '../../../MultiStore';
import { useQuery } from 'react-query';
import {
    getMetafieldAssociationMapping,
    getMetafieldAssociationMappingQueryKey,
} from '../api/getMetafieldAssociationMapping';
import { Associations } from '../model/MetafieldAssociationModel';
import { getPimAssociations, getPimAssociationsQueryKey } from '../api/getPimAssociations';
import * as InstabilityType from '../../../../util/InstabilityType';
import { Loading } from '../../../../component';
import { ManageMetafieldsButton } from '../../Attributes/component/ManageMetafieldsButton';
import styled from 'styled-components';
import { InstabilityStickyHelper } from '../../../../component/PageHeader/InstabilityStickyHelper';

const TitleContainer = styled(SectionTitle)`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;
export const AssociationsSelected = () => {
    const { currentStoreId } = useCurrentStore();
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);
    const [loading, setIsLoading] = useState(true);
    const [associationsWithinMapping, setAssociationsWithinMapping] = useState<Associations>([]);

    useQuery(
        [currentStoreId, getPimAssociationsQueryKey, getMetafieldAssociationMappingQueryKey],
        () =>
            Promise.all([
                getPimAssociations(currentStoreId),
                getMetafieldAssociationMapping(currentStoreId),
            ]),
        {
            onSuccess: ([{ pimAssociations = [], error = null }, { mapping }]) => {
                setIsLoading(false);

                if (error && error === InstabilityType.pim_instability) {
                    setPimHasInstability(true);
                    return;
                }

                if (pimAssociations && mapping) {
                    setAssociationsWithinMapping(
                        pimAssociations.filter((association) => mapping.includes(association.code)),
                    );
                }
            },
        },
    );

    return (
        <>
            <div>
                <Helper level='info'>
                    <FormattedMessage
                        id='1SDg/b'
                        defaultMessage='Metafields synchronized from PIM Associations allow you to integrate your recommendations for up-sell, cross-sell, or substitution in your Shopify store.'
                    />
                </Helper>
            </div>
            {associationsWithinMapping && !loading ? (
                associationsWithinMapping.length > 0 ? (
                    <>
                        <TitleContainer>
                            <SectionTitle.Title>
                                <FormattedMessage
                                    id='SSmYW0'
                                    defaultMessage='Metafields - PIM associations'
                                />
                            </SectionTitle.Title>
                            <ManageMetafieldsButton
                                noMappedMetafields={associationsWithinMapping.length <= 0}
                            />
                        </TitleContainer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    <FormattedMessage id='753yX5' defaultMessage='Label' />
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    <FormattedMessage id='h2vipu' defaultMessage='Code' />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage id='+U6ozc' defaultMessage='Type' />
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {associationsWithinMapping?.map((association) => (
                                    <Table.Row key={association.code}>
                                        <Table.Cell>
                                            <ColoredLabel>{association.label}</ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>{association.code}</Table.Cell>
                                        <Table.Cell>
                                            {association.isTwoWay ? '2-way' : '1-way'}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                ) : (
                    <NoMetafieldsFromPimAssociations />
                )
            ) : pimHasInstability ? (
                <InstabilityStickyHelper source='PIM' />
            ) : (
                <Loading />
            )}
        </>
    );
};
