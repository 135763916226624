import { castDraft, Immutable, produce } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { MappingStatus } from '../../model/MappingStatus';
import { PimAttributeMap } from '../../model/PimAttributeMap';
import { PimFamilyMap } from '../../model/PimFamilyMap';
import { PimAttributeErrors } from '../../model/PimAttributeErrors';

type ShopifyAttributeMap = { [shopifyAttributeCode: string]: ShopifyAttribute };
type ShopifySectionMap = {
    [partLabel: string]: { [shopifySectionLabel: string]: string[] };
};

export type State = Immutable<{
    shopifyAttributes: ShopifyAttributeMap;
    shopifyAttributeSections: ShopifySectionMap;
    pimAttributes: PimAttributeMap;
    pimFamilies: PimFamilyMap;
    productVariantMapping: Map<string, AttributeMapping>;
    productVariantMappingIsDirty: boolean;
    pimAttributeErrors: PimAttributeErrors | null;
}>;

export const initialState: State = {
    shopifyAttributes: {},
    shopifyAttributeSections: {},
    pimAttributes: {},
    pimFamilies: {},
    productVariantMapping: new Map(),
    productVariantMappingIsDirty: false,
    pimAttributeErrors: null,
};

export type Action =
    | {
          type: 'fetchAllData/fulfilled';
          shopifyAttributes: ShopifyAttributeMap;
          shopifyAttributeSections: ShopifySectionMap;
          pimAttributes: PimAttributeMap;
          pimFamilies: PimFamilyMap;
          productVariantMapping: {
              [shopifyAttributeCode: string]:
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: false;
                        data: string;
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: false;
                        data: { [pimFamilyCode: string]: string | null };
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: true;
                        data: string[];
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: true;
                        data: { [pimFamilyCode: string]: string[] | null };
                    };
          };
      }
    | {
          type: 'saveVariantMapping/fulfilled';
      }
    | {
          type: 'saveVariantMapping/rejected';
          pimAttributeErrors: PimAttributeErrors;
      }
    | {
          type: 'variantMapping/PimAttributeCodeChanged';
          shopifyAttributeCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'variantMapping/PimAttributeCodesChanged';
          shopifyAttributeCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'variantMapping/PimAttributeCodePerFamilyChanged';
          shopifyAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'variantMapping/PimAttributeCodesPerFamilyChanged';
          shopifyAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'variantMapping/HasAttributePerFamilyChanged';
          shopifyAttributeCode: string;
          hasAttributePerFamily: boolean;
      };

export const reducer = produce<(draft: State, action: Action) => State>((draft, action) => {
    switch (action.type) {
        case 'fetchAllData/fulfilled':
            draft.shopifyAttributeSections = castDraft(action.shopifyAttributeSections);
            draft.shopifyAttributes = castDraft(action.shopifyAttributes);
            draft.pimAttributes = action.pimAttributes;
            draft.pimFamilies = action.pimFamilies;
            draft.productVariantMapping = new Map();
            Object.values(action.productVariantMapping).forEach((attributeMapping) => {
                if (attributeMapping.hasAttributePerFamily) {
                    const familyMappingStatus =
                        Object.keys({ ...attributeMapping.data }).length ===
                        Object.keys(draft.pimFamilies).length
                            ? MappingStatus.Complete
                            : MappingStatus.InProgress;

                    if (attributeMapping.hasCollectionOfAttribute) {
                        draft.productVariantMapping.set(attributeMapping.shopifyAttributeCode, {
                            shopifyAttributeCode: attributeMapping.shopifyAttributeCode,
                            hasAttributePerFamily: attributeMapping.hasAttributePerFamily,
                            hasCollectionOfAttribute: attributeMapping.hasCollectionOfAttribute,
                            pimAttributeCodesPerFamily: attributeMapping.data,
                            status: familyMappingStatus,
                        });
                    } else {
                        draft.productVariantMapping.set(attributeMapping.shopifyAttributeCode, {
                            shopifyAttributeCode: attributeMapping.shopifyAttributeCode,
                            hasAttributePerFamily: attributeMapping.hasAttributePerFamily,
                            hasCollectionOfAttribute: attributeMapping.hasCollectionOfAttribute,
                            pimAttributeCodePerFamily: attributeMapping.data,
                            status: familyMappingStatus,
                        });
                    }
                } else {
                    if (attributeMapping.hasCollectionOfAttribute) {
                        draft.productVariantMapping.set(attributeMapping.shopifyAttributeCode, {
                            shopifyAttributeCode: attributeMapping.shopifyAttributeCode,
                            hasAttributePerFamily: attributeMapping.hasAttributePerFamily,
                            hasCollectionOfAttribute: attributeMapping.hasCollectionOfAttribute,
                            pimAttributeCodes: attributeMapping.data,
                            status: MappingStatus.Complete,
                        });
                    } else {
                        draft.productVariantMapping.set(attributeMapping.shopifyAttributeCode, {
                            shopifyAttributeCode: attributeMapping.shopifyAttributeCode,
                            hasAttributePerFamily: attributeMapping.hasAttributePerFamily,
                            hasCollectionOfAttribute: attributeMapping.hasCollectionOfAttribute,
                            pimAttributeCode: attributeMapping.data,
                            status: MappingStatus.Complete,
                        });
                    }
                }
            });
            draft.productVariantMappingIsDirty = false;
            break;

        case 'saveVariantMapping/fulfilled':
            draft.productVariantMappingIsDirty = false;
            break;

        case 'variantMapping/PimAttributeCodeChanged':
            if (null !== action.pimAttributeCode) {
                draft.productVariantMapping.set(action.shopifyAttributeCode, {
                    shopifyAttributeCode: action.shopifyAttributeCode,
                    hasCollectionOfAttribute: false,
                    hasAttributePerFamily: false,
                    pimAttributeCode: action.pimAttributeCode,
                    status: MappingStatus.Complete,
                });
            } else {
                draft.productVariantMapping.delete(action.shopifyAttributeCode);
            }
            draft.productVariantMappingIsDirty = true;
            break;

        case 'variantMapping/PimAttributeCodesChanged':
            if (action.pimAttributeCodes.length !== 0) {
                draft.productVariantMapping.set(action.shopifyAttributeCode, {
                    shopifyAttributeCode: action.shopifyAttributeCode,
                    hasCollectionOfAttribute: true,
                    hasAttributePerFamily: false,
                    pimAttributeCodes: action.pimAttributeCodes,
                    status: MappingStatus.Complete,
                });
            } else {
                draft.productVariantMapping.delete(action.shopifyAttributeCode);
            }
            draft.productVariantMappingIsDirty = true;
            break;

        case 'variantMapping/HasAttributePerFamilyChanged':
            if (true === action.hasAttributePerFamily) {
                const attributeMapping = draft.productVariantMapping.get(
                    action.shopifyAttributeCode,
                );

                const shopifyAttribute = draft.shopifyAttributes[action.shopifyAttributeCode];

                if (attributeMapping?.hasAttributePerFamily || shopifyAttribute === undefined) {
                    throw new Error();
                }

                if (shopifyAttribute.collection) {
                    let pimAttributeCodesPerFamily = {};
                    draft.productVariantMapping.set(action.shopifyAttributeCode, {
                        shopifyAttributeCode: action.shopifyAttributeCode,
                        hasCollectionOfAttribute: true,
                        hasAttributePerFamily: true,
                        pimAttributeCodesPerFamily,
                        status:
                            Object.keys(pimAttributeCodesPerFamily).length ===
                            Object.keys(draft.pimFamilies).length
                                ? MappingStatus.Complete
                                : MappingStatus.InProgress,
                    });
                } else {
                    let pimAttributeCodePerFamily = {};

                    draft.productVariantMapping.set(action.shopifyAttributeCode, {
                        shopifyAttributeCode: action.shopifyAttributeCode,
                        hasCollectionOfAttribute: false,
                        hasAttributePerFamily: true,
                        pimAttributeCodePerFamily,
                        status:
                            Object.keys(pimAttributeCodePerFamily).length ===
                            Object.keys(draft.pimFamilies).length
                                ? MappingStatus.Complete
                                : MappingStatus.InProgress,
                    });
                }
            } else {
                draft.productVariantMapping.delete(action.shopifyAttributeCode);
            }
            draft.productVariantMappingIsDirty = true;
            break;

        case 'variantMapping/PimAttributeCodePerFamilyChanged':
            const attributeMapping = draft.productVariantMapping.get(
                action.shopifyAttributeCode,
            ) || {
                shopifyAttributeCode: action.shopifyAttributeCode,
                hasAttributePerFamily: true,
                hasCollectionOfAttribute: false,
                pimAttributeCodePerFamily: {},
                status: MappingStatus.InProgress,
            };

            if (
                !attributeMapping.hasAttributePerFamily ||
                attributeMapping.hasCollectionOfAttribute
            ) {
                throw new Error();
            }

            if (null !== action.pimAttributeCode) {
                attributeMapping.pimAttributeCodePerFamily[action.pimFamilyCode] =
                    action.pimAttributeCode;
                const isFamilyMappingComplete =
                    Object.keys(attributeMapping.pimAttributeCodePerFamily).length ===
                    Object.keys(draft.pimFamilies).length;
                attributeMapping.status = isFamilyMappingComplete
                    ? MappingStatus.Complete
                    : MappingStatus.InProgress;
            } else {
                delete attributeMapping.pimAttributeCodePerFamily[action.pimFamilyCode];
                attributeMapping.status = MappingStatus.InProgress;
            }

            draft.productVariantMapping.set(action.shopifyAttributeCode, attributeMapping);
            draft.productVariantMappingIsDirty = true;
            break;

        case 'variantMapping/PimAttributeCodesPerFamilyChanged':
            const familyAttributeMapping = draft.productVariantMapping.get(
                action.shopifyAttributeCode,
            ) || {
                shopifyAttributeCode: action.shopifyAttributeCode,
                hasAttributePerFamily: true,
                hasCollectionOfAttribute: true,
                pimAttributeCodesPerFamily: {},
                status: MappingStatus.InProgress,
            };

            if (
                !familyAttributeMapping.hasAttributePerFamily ||
                !familyAttributeMapping.hasCollectionOfAttribute
            ) {
                throw new Error();
            }

            if (action.pimAttributeCodes.length !== 0) {
                familyAttributeMapping.pimAttributeCodesPerFamily[action.pimFamilyCode] =
                    action.pimAttributeCodes;
                const isFamilyMappingComplete =
                    Object.keys(familyAttributeMapping.pimAttributeCodesPerFamily).length ===
                    Object.keys(draft.pimFamilies).length;
                familyAttributeMapping.status = isFamilyMappingComplete
                    ? MappingStatus.Complete
                    : MappingStatus.InProgress;
            } else {
                delete familyAttributeMapping.pimAttributeCodesPerFamily[action.pimFamilyCode];
                familyAttributeMapping.status = MappingStatus.InProgress;
            }

            draft.productVariantMapping.set(action.shopifyAttributeCode, familyAttributeMapping);
            draft.productVariantMappingIsDirty = true;
            break;
        case 'saveVariantMapping/rejected':
            // @ts-ignore
            draft.pimAttributeErrors = action.pimAttributeErrors;
            break;
    }

    return draft;
});
