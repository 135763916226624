import { useQuery } from 'react-query';
import { useCurrentStore } from '../../../../../MultiStore';
import { getPimAttributes } from '../../../api/getPimAttributes';

export const usePimImageAttributes = (): {
    isLoading: boolean;
    attributes: Array<{
        code: string;
        label: string;
    }>;
} => {
    const { currentStoreId } = useCurrentStore();

    const { isLoading, data } = useQuery('getPimAttributes', () =>
        getPimAttributes(currentStoreId),
    );

    if (!data) {
        return { isLoading, attributes: [] };
    }

    const attributes = Object.values(data.pimAttributes)
        .filter(({ type }) => type === 'pim_catalog_image')
        .sort(({ label: a }, { label: b }) => {
            a = a.toUpperCase();
            b = b.toUpperCase();
            return a < b ? -1 : a > b ? 1 : 0;
        });

    return { isLoading, attributes };
};
