import { AssetMapping } from '../model/AssetMapping';
import { ImageMapping } from '../model/ImageMapping';
import { MediaSource } from '../../../model/MediaSource';
import { apiFetch } from '../../../../../../util/apiFetch';
import { MediaMappingScope } from '../../../model/MediaMappingScope';
type Result =
    | {
          source: MediaSource.Images;
          mediaMapping: ImageMapping;
      }
    | {
          source: MediaSource.Assets;
          mediaMapping: AssetMapping;
      };

export const getMediaMapping = (storeId: string, mediaMappingScope: MediaMappingScope) =>
    apiFetch<Result>(
        `${storeId}/mapping/media-mapping?media_mapping_scope=${mediaMappingScope}`,
    ).then((result) => result);
