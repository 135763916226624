import { Button, Field, Helper, Link, SectionTitle, SelectInput } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { SpacerSmall } from '../../../../../component/Spacer';
import { MediaSource } from '../../model/MediaSource';
import { useDispatch } from '../../Product/MediaMapping/store/StoreProvider';
import { helpCenterMediaMappingUrl } from '../../../../../util/config';

type Props = {
    contextProduct: String;
    value: MediaSource;
    onChange: (value: MediaSource) => void;
    handleImageMappingChange: (value: { attributeCode: string | null }) => void;
    handleAssetMappingChange: (
        value:
            | { attributeCode: null }
            | { attributeCode: string; mediaAssetAttributeCode: null | string },
    ) => void;
    disabled: boolean;
};

const ClearButtonContainer = styled.div`
    text-align: end;
`;

export const SelectMediaSource = ({
    contextProduct,
    value,
    onChange,
    handleImageMappingChange,
    handleAssetMappingChange,
    disabled,
}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const clearSelection = () => {
        if (value === MediaSource.Images) {
            if (contextProduct === 'native-field') {
                dispatch({
                    type: 'mediaMapping/mapping/clear',
                });
            }
            if (contextProduct === 'variant') {
                handleImageMappingChange({ attributeCode: null });
            }
        }
        if (value === MediaSource.Assets) {
            if (contextProduct === 'native-field') {
                dispatch({
                    type: 'assetMapping/mapping/clear',
                });
            }
            if (contextProduct === 'variant') {
                handleAssetMappingChange({ attributeCode: null });
            }
        }
    };
    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='zdz0XO' defaultMessage='Select media source' />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
                <ClearButtonContainer>
                    <Button
                        ghost
                        level='danger'
                        onClick={() => clearSelection()}
                        disabled={!disabled}
                    >
                        <FormattedMessage id='EYIw2M' defaultMessage='Clear selection' />
                    </Button>
                </ClearButtonContainer>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='ft+WQM'
                    defaultMessage='You have the possibility to map either image attributes or asset collections.'
                />{' '}
                <Link href={helpCenterMediaMappingUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>

            <SpacerSmall />

            <Field
                label={intl.formatMessage({
                    id: 'OK0FDH',
                    defaultMessage: 'Media source',
                })}
            >
                <SelectInput
                    readOnly={disabled}
                    openLabel=''
                    emptyResultLabel=''
                    value={value}
                    onChange={(value) => onChange(value as MediaSource)}
                    clearable={false}
                >
                    <SelectInput.Option value={MediaSource.Images}>
                        <FormattedMessage id='Fip4H8' defaultMessage='Images' />
                    </SelectInput.Option>
                    <SelectInput.Option value={MediaSource.Assets}>
                        <FormattedMessage id='RMFqkI' defaultMessage='Asset collections' />
                    </SelectInput.Option>
                </SelectInput>
                {disabled && (
                    <Helper inline level='info'>
                        <FormattedMessage
                            id='norn9r'
                            defaultMessage='If you want to change the media source, you have to clear your attribute selection.'
                        />
                    </Helper>
                )}
            </Field>
        </>
    );
};
