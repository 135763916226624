import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import { useState } from 'react';
import { resetAppConfiguration } from '../api/resetAppConfiguration';
import { useDispatch } from '../store/StoreProvider';
import { useCurrentStore } from '../../MultiStore/CurrentStoreContext';

export const useResetConfiguration = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const { currentStoreId } = useCurrentStore();

    const [isResetting, setIsResetting] = useState(false);

    const reset = async () => {
        setIsResetting(true);

        await resetAppConfiguration(currentStoreId);

        dispatch({ type: 'connectorConfigForm/reset' });

        addToast(
            intl.formatMessage({
                id: 'gFHVqt',
                defaultMessage: 'Apps successfully reset.',
            }),
            'success',
        );

        setIsResetting(false);
    };

    return { reset, isResetting };
};
