import { Immutable } from 'immer';
import { AssetMapping } from '../model/AssetMapping';
import { apiFetch } from '../../../../util/apiFetch';

type AssetCollectionsMapping = {
    storeId: string;
    data: Immutable<AssetMapping>;
};

export const saveAssetCollectionsMapping = (mapping: AssetCollectionsMapping) =>
    apiFetch(`${mapping.storeId}/mapping/save-metafield-media-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mapping: mapping.data,
        }),
    });
