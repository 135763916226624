import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getStores } from './api/getStores';
import { CurrentStoreIdContext } from './CurrentStoreContext';

type Props = {
    children: React.ReactNode;
    onStoreNotFound: () => void;
};

export const StoreRouteGuard = ({ children, onStoreNotFound }: Props) => {
    const { storeId } = useParams();
    if (undefined === storeId) {
        throw new Error();
    }

    const context = useContext(CurrentStoreIdContext);
    if (null === context) {
        throw new Error('<CurrentStoreProvider /> not setup');
    }

    useQuery('stores', getStores, {
        enabled: storeId !== context.currentStoreId,
        onSuccess: (data) => {
            if (false === data.some(({ id, status }) => id === storeId && status === 'CONNECTED')) {
                context.setCurrentStoreId(null);
                onStoreNotFound();
            }

            context.setCurrentStoreId(storeId);
        },
    });

    if (null === context.currentStoreId || storeId !== context.currentStoreId) {
        return <></>;
    }

    return <>{children}</>;
};
