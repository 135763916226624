import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { saveAssetCollectionsMapping } from '../api/saveAssetCollectionsMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useToaster } from '../../../../util/toast';
import { useCurrentStore } from '../../../MultiStore';

export const useSaveAssetCollectionsMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const saveAssetCollectionsMappingMutation = useMutation(saveAssetCollectionsMapping);
    const { currentStoreId } = useCurrentStore();
    const { assetMapping } = useSelector((state) => state);

    const saveMapping = async () => {
        await saveAssetCollectionsMappingMutation.mutateAsync({
            storeId: currentStoreId,
            data: assetMapping,
        });

        dispatch({
            type: 'assetMapping/source/saved',
            mappingIsDirty: false,
        });

        addToast(
            intl.formatMessage({
                id: 'zjRFXy',
                defaultMessage: 'Asset Collections mapping successfully updated.',
            }),
            'success',
        );
    };

    return { saveMapping };
};
