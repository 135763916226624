import { ArrowDownIcon, Dropdown, TabBar, useBooleanState } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import {
    matchPath,
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { AppNamespaceDeactivated, Navigation, PageContent, PageHeader, Spacer } from '../component';
import { GoToPimLink } from '../component/GoToPimLink';
import { Announcements } from '../page/Announcements';
import { Configuration } from '../page/Configuration';
import { Mapping } from '../page/NativeFields/SingleMapping';
import {
    MultiStore,
    SelectStoreDropdown,
    StoreBreadcrumb,
    StoreRouteGuard,
} from '../page/MultiStore';
import { SynchronizationTab } from '../page/Synchronization';
import { FeatureGuard, useFeatureFlags } from '../util/featureFlag';
import { FromShopify } from '../page/Authentication/FromShopify';
import { HelpCenter } from '../page/HelpCenter';
import { VariantMapping } from '../page/NativeFields/VariantMapping';
import { MetafieldAttributes } from '../page/Metafield/Attributes';
import { ShopifyOauthError, PimOauthError } from '../page/ErrorBoundary';
import { StoreScopesOutdated } from '../page/AccessScope/StoreScopesOutdated';
import { MetafieldAssociations } from '../page/Metafield/Associations/MetafieldAssociations';
import { MetafieldAssetCollections } from '../page/Metafield/AssetCollections/MetafieldAssetCollections';
import { MetaobjectsTab } from '../page/Metaobject/MetaobjectsTab';

const MainNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, open, close] = useBooleanState(false);

    return (
        <TabBar moreButtonTitle={'...'}>
            <TabBar.Tab
                isActive={null !== matchPath('/stores/:storeId/configuration', location.pathname)}
                onClick={() => navigate('configuration')}
            >
                <FormattedMessage id='7OW8BT' defaultMessage='Configuration' />
            </TabBar.Tab>
            <TabBar.Tab
                isActive={
                    null !== matchPath('/stores/:storeId/mapping/native-field/*', location.pathname)
                }
                onClick={() => navigate('mapping/native-field')}
            >
                <FormattedMessage id='7NFfmz' defaultMessage='Products' />
            </TabBar.Tab>
            <TabBar.Tab
                isActive={
                    null !== matchPath('/stores/:storeId/variant-mapping/*', location.pathname)
                }
                onClick={() => navigate('variant-mapping/native-field')}
            >
                <FormattedMessage id='QE0Qxn' defaultMessage='Products with variants' />
            </TabBar.Tab>
            <TabBar.Tab
                isActive={
                    null !==
                        matchPath('/stores/:storeId/mapping/metafields/*', location.pathname) ||
                    null !==
                        matchPath('/stores/:storeId/mapping/associations/*', location.pathname) ||
                    null !==
                        matchPath(
                            '/stores/:storeId/mapping/metafield-asset-collections/*',
                            location.pathname,
                        )
                }
            >
                <Dropdown>
                    <div onClick={open}>
                        <FormattedMessage id='i1xTYg' defaultMessage='Metafields' />{' '}
                        <ArrowDownIcon size={12} />
                    </div>
                    {isOpen && (
                        <Dropdown.Overlay
                            dropdownOpenerVisible={true}
                            verticalPosition='down'
                            onClose={close}
                        >
                            <Dropdown.ItemCollection>
                                <Dropdown.Item
                                    isActive={
                                        null !==
                                        matchPath(
                                            '/stores/:storeId/mapping/metafields/*',
                                            location.pathname,
                                        )
                                    }
                                    onClick={() => navigate('mapping/metafields')}
                                >
                                    <FormattedMessage
                                        id='yp3q//'
                                        defaultMessage='From PIM attributes'
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item
                                    isActive={
                                        null !==
                                        matchPath(
                                            '/stores/:storeId/mapping/associations/*',
                                            location.pathname,
                                        )
                                    }
                                    onClick={() => navigate('mapping/associations')}
                                >
                                    <FormattedMessage
                                        id='IZiD5K'
                                        defaultMessage='From PIM associations'
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item
                                    isActive={
                                        null !==
                                        matchPath(
                                            '/stores/:storeId/mapping/metafield-asset-collections/*',
                                            location.pathname,
                                        )
                                    }
                                    onClick={() => navigate('mapping/metafield-asset-collections')}
                                >
                                    <FormattedMessage
                                        id='akDW7J'
                                        defaultMessage='From PIM asset collections'
                                    />
                                </Dropdown.Item>
                            </Dropdown.ItemCollection>
                        </Dropdown.Overlay>
                    )}
                </Dropdown>
            </TabBar.Tab>
            <FeatureGuard feature='enable_metaobjects_synchronization'>
                {/* We need to add a new TabBar otherwise we have an error*/}
                {/* ==> Error: TabBar.Tab can not be used outside TabBar */}
                {/* TODO remove the TabBar once in available for all */}
                <TabBar moreButtonTitle={'...'}>
                    <TabBar.Tab
                        isActive={
                            null !== matchPath('/stores/:storeId/metaobjects', location.pathname)
                        }
                        onClick={() => navigate('metaobjects')}
                    >
                        <FormattedMessage id='rxYYWb' defaultMessage='Metaobjects' />
                    </TabBar.Tab>
                </TabBar>
            </FeatureGuard>
            <TabBar.Tab
                isActive={null !== matchPath('/stores/:storeId/execution', location.pathname)}
                onClick={() => navigate('execution')}
            >
                <FormattedMessage id='eOsloh' defaultMessage='Synchronization' />
            </TabBar.Tab>
        </TabBar>
    );
};

const StorePageContent = () => (
    <>
        <StoreBreadcrumb />
        <SelectStoreDropdown />
        <Spacer />
        <MainNavigation />
        <Outlet />
    </>
);

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const { isLoading, isFeatureEnabled } = useFeatureFlags();

    if (!isFeatureEnabled('app_is_available')) {
        return (
            <>
                <PageHeader />
                <PageContent>{isLoading ? <></> : <AppNamespaceDeactivated />}</PageContent>
            </>
        );
    }

    return (
        <>
            <PageHeader onClick={() => navigate('/')}>
                <Navigation>
                    <Routes>
                        <Route path='/deactivated' element={null} />
                        <Route path='*' element={<GoToPimLink />} />
                    </Routes>
                </Navigation>
                <HelpCenter />
                <Announcements />
            </PageHeader>
            <PageContent>
                <Routes>
                    <Route
                        path='/stores/:storeId'
                        element={
                            <StoreRouteGuard onStoreNotFound={() => navigate('/')}>
                                <StorePageContent />
                            </StoreRouteGuard>
                        }
                    >
                        <Route path='configuration/*' element={<Configuration />} />
                        <Route path='mapping/*' element={<Mapping />} />
                        <Route path='variant-mapping/*' element={<VariantMapping />} />
                        <Route path='mapping/metafields/*' element={<MetafieldAttributes />} />
                        <Route path='mapping/associations/*' element={<MetafieldAssociations />} />
                        <Route
                            path='mapping/metafield-asset-collections/*'
                            element={<MetafieldAssetCollections />}
                        />
                        <Route path='metaobjects/*' element={<MetaobjectsTab />} />
                        <Route path='execution/*' element={<SynchronizationTab />} />
                        <Route path='*' element={<Navigate to='/stores' replace />} />
                    </Route>
                    <Route path='/deactivated' element={<AppNamespaceDeactivated />} />
                    <Route path='/stores/*' element={<MultiStore />} />
                    <Route path='/stores/:storeId/outdated' element={<StoreScopesOutdated />} />
                    <Route path='*' element={<Navigate to='/stores' replace />} />
                </Routes>
            </PageContent>
        </>
    );
};

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/authentication/from-shopify' element={<FromShopify />} />
            <Route path='/authentication/shopify-oauth-error' element={<ShopifyOauthError />} />
            <Route path='/authentication/pim-oauth-error' element={<PimOauthError />} />
            <Route path='*' element={<PrivateRoutes />} />
        </Routes>
    );
};
