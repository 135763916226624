import React from 'react';
import { AllowedPimAttributeMultiselect } from '../component/AllowedPimAttributeMultiselect';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    shopifyAttribute: ShopifyAttribute;
    pimFamilyCode: string;
};

export const FamilyPimAttributeCollectionSelector = ({
    shopifyAttribute,
    pimFamilyCode,
}: Props) => {
    const dispatch = useDispatch();

    const pimAttributeCodes = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(shopifyAttribute.code)(state);
        if (!attributeMapping.hasAttributePerFamily) {
            throw new Error();
        }
        if (!attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        const attributeCodes = attributeMapping.pimAttributeCodesPerFamily[pimFamilyCode];
        if (!attributeCodes) {
            return [];
        }

        return attributeCodes;
    });

    const handleChange = (pimAttributeCodes: string[]) =>
        dispatch({
            type: 'mapping/PimAttributeCodesPerFamilyChanged',
            shopifyAttributeCode: shopifyAttribute.code,
            pimFamilyCode,
            pimAttributeCodes,
        });

    return (
        <AllowedPimAttributeMultiselect
            shopifyAttribute={shopifyAttribute}
            selectedPimAttributeCodes={pimAttributeCodes as string[]}
            pimFamilyCode={pimFamilyCode}
            onChange={handleChange}
        />
    );
};
