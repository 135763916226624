import { Badge } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SynchronizationStatus } from '../../model/SynchronizationStatus';

type Props = {
    status: SynchronizationStatus;
};

export const SynchronizationStatusBadge = ({ status }: Props) => {
    switch (status) {
        case SynchronizationStatus.Pending:
            return (
                <Badge level='tertiary'>
                    <FormattedMessage id='eKEL/g' defaultMessage='Pending' />
                </Badge>
            );
        case SynchronizationStatus.InProgress:
            return (
                <Badge level='secondary'>
                    <FormattedMessage id='q1WWIr' defaultMessage='In progress' />
                </Badge>
            );
        case SynchronizationStatus.Stopping:
            return (
                <Badge level='danger'>
                    <FormattedMessage id='N728MF' defaultMessage='Stopping' />
                </Badge>
            );
    }

    return null;
};
