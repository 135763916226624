import { apiFetch, BadRequestError } from '../../../util/apiFetch';
import { PublishingChannel } from '../PublishingSettings/model/PublishingChannel';

type Config = {
    categoryCode: string;
    locale: string | null;
    channel: string | null;
    currency: string | null;
    preExistingCatalogMapping?: {
        product_shopify_reference_field: string | null;
        product_pim_product_identifier: string | null;
        variant_shopify_reference_field: string | null;
        variant_pim_product_identifier: string | null;
    };
    publishingSettings: {
        salesChannels: PublishingChannel[];
        b2bCatalogs: PublishingChannel[];
    };
};

export const saveConnectorConfig = (config: Config, currentStoreId: string) =>
    apiFetch(`${currentStoreId}/configuration/save-pim-config`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            categoryCode: config.categoryCode,
            locale: config.locale || '',
            channel: config.channel || '',
            currency: config.currency || '',
            product_shopify_reference_field:
                config.preExistingCatalogMapping?.product_shopify_reference_field || '',
            product_pim_product_identifier:
                config.preExistingCatalogMapping?.product_pim_product_identifier || '',
            variant_shopify_reference_field:
                config.preExistingCatalogMapping?.variant_shopify_reference_field || '',
            variant_pim_product_identifier:
                config.preExistingCatalogMapping?.variant_pim_product_identifier || '',
            publishing_settings_sales_channels: config.publishingSettings.salesChannels || [],
            publishing_settings_b2b_catalogs: config.publishingSettings.b2bCatalogs || [],
        }),
    });

export const isSaveConnectorConfigValidationError = (
    error: unknown,
): error is BadRequestError<{
    errors: {
        categoryCode: string[];
        locale: string[];
        channel: string[];
        currency: string[];
        product_shopify_reference_field?: string[];
        product_pim_product_identifier?: string[];
        variant_shopify_reference_field?: string[];
        variant_pim_product_identifier?: string[];
        publishing_settings_sales_channels: string[];
        publishing_settings_b2b_catalogs: string[];
    };
}> => error instanceof BadRequestError;
