import { Dropdown, SwitcherButton, useBooleanState } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactNode } from 'react';

type Filter = 'all' | 'selected' | 'unselected';

type Props = {
    value: Filter;
    onChange: (value: 'all' | 'selected' | 'unselected') => void;
};

export const SelectedFilter = ({ value, onChange }: Props) => {
    const intl = useIntl();
    const [isOpen, open, close] = useBooleanState(false);

    const options: { [key in Filter]: ReactNode } = {
        all: <FormattedMessage id='zQvVDJ' defaultMessage='All' />,
        selected: <FormattedMessage id='byP6IC' defaultMessage='Selected' />,
        unselected: <FormattedMessage id='N/CtWu' defaultMessage='Unselected' />,
    };

    return (
        <Dropdown>
            <SwitcherButton
                label={intl.formatMessage({
                    id: '64NAin',
                    defaultMessage: 'Display',
                })}
                onClick={open}
            >
                {options[value]}
            </SwitcherButton>
            {isOpen && (
                <Dropdown.Overlay
                    dropdownOpenerVisible={true}
                    onClose={close}
                    verticalPosition='down'
                >
                    <Dropdown.ItemCollection>
                        {Object.entries(options).map(([option, labelElement]) => (
                            <Dropdown.Item key={option} onClick={() => onChange(option as Filter)}>
                                {labelElement}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.ItemCollection>
                </Dropdown.Overlay>
            )}
        </Dropdown>
    );
};
