import { getColor, getFontSize } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { apiFetch } from '../util/apiFetch';
import { ReturnIcon } from './ReturnIcon';

const Container = styled.div`
    margin: 0 0 0 auto;
`;

const Link = styled.a`
    color: ${getColor('grey', 120)};
    font-size: ${getFontSize('big')};
    text-decoration: none;
    margin-right: 5px;
`;

export const GoToPimLink = () => {
    const result = useQuery('retrieve-pim-url', () =>
        apiFetch<{ pimUrl: string }>('retrieve-pim-url'),
    );

    if (!result.isSuccess) {
        return null;
    }

    return (
        <Container>
            <Link href={result.data.pimUrl} target={'_blank'}>
                <FormattedMessage id='gZmiLL' defaultMessage='Go to Akeneo PIM' />
            </Link>
            <ReturnIcon />
        </Container>
    );
};
