import { apiFetch } from '../../../util/apiFetch';

export type storeWithSynchronizationRunning = {
    storeIds: Array<string>;
};

export const retrieveStoreWithSynchronizationRunning = () =>
    apiFetch<storeWithSynchronizationRunning>(
        'store/retrieve-stores-with-synchronization-running',
    ).then((result) => {
        return result;
    });
