import { Helper, Link, SectionTitle, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SpacerSmall } from '../../../../../../component/Spacer';
import { usePimImageAttributes } from '../hook/usePimImageAttributes';
import { Loading } from '../../../../../../component';
import { ImageAttributeItem } from './ImageAttributeItem';
import { helpCenterMediaMappingHowToMapMediaUrl } from '../../../../../../util/config';
import { ImageMappingValue } from '../model/MediaMappingValue';

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

type Props = {
    value: ImageMappingValue;
    onChange: (value: ImageMappingValue) => void;
};

export const SelectImageAttribute = ({ value, onChange }: Props) => {
    const { isLoading, attributes } = usePimImageAttributes();

    const handleChange = (value: ImageMappingValue) => onChange(value);

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='zbDn+Q' defaultMessage='Select image attribute' />
                </SectionTitle.Title>
            </SectionTitle>
            <Helper level='info'>
                <FormattedMessage
                    id='eiGKFo'
                    defaultMessage='The App supports one image per variant.'
                />{' '}
                <Link href={helpCenterMediaMappingHowToMapMediaUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>
            <SpacerSmall />
            {isLoading ? (
                <Loading />
            ) : (
                <Table>
                    <Table.Header>
                        <Table.HeaderCell>
                            <FormattedMessage id='OsU2Fs' defaultMessage='Attribute' />
                        </Table.HeaderCell>
                        <Space />
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <ImageAttributeItem
                                attributes={attributes}
                                value={value}
                                onChange={handleChange}
                            />
                        </Table.Row>
                    </Table.Body>
                </Table>
            )}
        </>
    );
};
