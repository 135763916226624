import { Button, Helper, Link, SectionTitle, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useQuery } from 'react-query';
import { getPimAssetAttributes } from '../api/getPimAssetCollections';
import { useCurrentStore } from '../../../MultiStore';
import { Loading } from '../../../../component';
import { helpCenterAssetCollectionsMetafieldMappingHowToMapMediaUrl } from '../../../../util/config';
import React from 'react';
import { AssetAttributeItem } from './AssetAttributeItem';
import { SpacerSmall } from '../../../../component/Spacer';
import { useSaveAssetCollectionsMapping } from '../hook/useSaveAssetCollectionsMapping';

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

type Props = {
    maxAttribute: number;
};

export const AssetCollectionList = ({ maxAttribute }: Props) => {
    const { assetMapping, mediaAssetCollectionAttributes, mappingIsDirty } = useSelector(
        (state) => state,
    );
    const dispatch = useDispatch();

    const { currentStoreId } = useCurrentStore();

    const { saveMapping } = useSaveAssetCollectionsMapping();
    const handleSave = () => {
        saveMapping();
    };

    const { isFetching: isFetchingAsset } = useQuery(
        [currentStoreId, 'getPimAssetAttributes'],
        () => getPimAssetAttributes(currentStoreId),
        {
            onSuccess: (result) => {
                dispatch({
                    type: 'assetMapping/fetch/collections',
                    assetCollectionAttributes: result.assetCollectionAttributes,
                });
                dispatch({
                    type: 'assetMapping/fetch/attributes',
                    mediaAssetAttributesPerAssetFamily: result.mediaAssetAttributesPerAssetFamily,
                    altTextAssetAttributesPerAssetFamily:
                        result.altTextAssetAttributesPerAssetFamily,
                });
            },
        },
    );

    return (
        <>
            {isFetchingAsset ? (
                <Loading />
            ) : (
                <>
                    <SaveButtonContainer>
                        {mappingIsDirty && (
                            <Helper inline level='warning'>
                                <FormattedMessage
                                    id='2xq0/g'
                                    defaultMessage='It seems that some modifications are unsaved'
                                />
                            </Helper>
                        )}
                        <Button level='primary' disabled={!mappingIsDirty} onClick={handleSave}>
                            <FormattedMessage id='jvo0vs' defaultMessage='Save' />
                        </Button>
                    </SaveButtonContainer>
                    <SectionTitle>
                        <SectionTitle.Title>
                            <FormattedMessage
                                id='CdOPFQ'
                                defaultMessage='Metafields - PIM asset collections'
                            />
                        </SectionTitle.Title>
                    </SectionTitle>
                    <Helper level='info'>
                        <FormattedMessage
                            id='JOZPkH'
                            values={{
                                maxAssetCollections: maxAttribute,
                            }}
                            defaultMessage='Metafields synchronized from PIM asset collections allow you to integrate files such as images, videos, PDFs, and more into your Shopify store.
                            You can map up to {maxAssetCollections} asset collections to sync as file metafields.'
                        />{' '}
                        <Link
                            href={helpCenterAssetCollectionsMetafieldMappingHowToMapMediaUrl}
                            target='_blank'
                        >
                            <FormattedMessage
                                id='i/qJsQ'
                                defaultMessage='Please check the Help Center for more information.'
                            />
                        </Link>
                    </Helper>
                    <SpacerSmall></SpacerSmall>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>
                                <FormattedMessage id='0mJgQP' defaultMessage='Asset collection' />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='zqI1ix'
                                    defaultMessage='Asset attribute - media'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='R3jjUa'
                                    defaultMessage='Asset attribute type'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage id='PxCdsG' defaultMessage='Media type' />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='hQgVT7'
                                    defaultMessage='Asset attribute - alt-text'
                                />
                            </Table.HeaderCell>
                            <Space />
                        </Table.Header>
                        <Table.Body>
                            {assetMapping.map((attributeCode, index) => {
                                const assetCollections = mediaAssetCollectionAttributes.get(
                                    attributeCode.attribute_code,
                                );
                                if (assetCollections !== undefined) {
                                    return (
                                        <Table.Row
                                            key={
                                                attributeCode.attribute_code +
                                                '#' +
                                                attributeCode.asset_attribute_code
                                            }
                                        >
                                            <AssetAttributeItem
                                                assetCollections={assetCollections}
                                                mediaAssetAttribute={
                                                    attributeCode.asset_attribute_code
                                                }
                                                altTextAssetAttribute={
                                                    attributeCode.asset_attribute_code_alt_text
                                                }
                                                index={index}
                                            />
                                        </Table.Row>
                                    );
                                }
                                return <></>;
                            })}
                            {assetMapping.length !== maxAttribute && (
                                <Table.Row key={'newAttribute'}>
                                    <AssetAttributeItem
                                        key={assetMapping.length}
                                        assetCollections={null}
                                        mediaAssetAttribute={null}
                                        altTextAssetAttribute={null}
                                        index={null}
                                    />
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    {assetMapping.length === maxAttribute && (
                        <Helper level='warning'>
                            <FormattedMessage
                                id='yFJw8u'
                                values={{
                                    maxAssetCollections: maxAttribute,
                                }}
                                defaultMessage='You have reached the limit of {maxAssetCollections} asset collections per product.'
                            />{' '}
                            <Link
                                href={helpCenterAssetCollectionsMetafieldMappingHowToMapMediaUrl}
                                target='_blank'
                            >
                                <FormattedMessage
                                    id='i/qJsQ'
                                    defaultMessage='Please check the Help Center for more information.'
                                />
                            </Link>
                        </Helper>
                    )}
                </>
            )}
        </>
    );
};
