import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useToaster } from '../../../../util/toast';
import { useSaveMapping } from '../Product/hook/useSaveMapping';
import { Immutable } from 'immer';
import { AttributeMapping } from '../model/AttributeMapping';
import { useDispatch } from '../Product/store/StoreProvider';
import { isSaveProductMappingValidationError } from '../api/saveMapping';

export const useSaveSingleMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const saveProductMappingForm = useSaveMapping();

    const [isSaving, setIsSaving] = useState(false);

    const save = async (mapping: Immutable<Map<string, AttributeMapping>>) => {
        setIsSaving(true);
        try {
            await saveProductMappingForm(mapping);
            dispatch({ type: 'saveMapping/fulfilled' });

            addToast(
                intl.formatMessage({
                    id: 'o1bsib',
                    defaultMessage: 'Mapping successfully updated.',
                }),
                'success',
            );
        } catch (error) {
            if (!isSaveProductMappingValidationError(error)) {
                throw error;
            }

            dispatch({
                type: 'saveMapping/rejected',
                pimAttributeErrors: error.data.errors,
            });

            addToast(
                intl.formatMessage({
                    id: 'RJQRyP',
                    defaultMessage:
                        'Mapping not saved. One or more attributes no longer exist in the PIM. Please make sure to update the mapping with valid attributes.',
                }),
                'error',
            );
        }

        setIsSaving(false);
    };

    return { save, isSaving };
};
