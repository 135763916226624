import { apiFetch } from '../../../util/apiFetch';
import { Synchronization } from '../model/Synchronization';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { SynchronizationStatus } from '../model/SynchronizationStatus';
import { SynchronizationType } from '../model/SynchronizationType';

const mapSynchronizationType = (type: 'FULL' | 'DIFFERENTIAL') => {
    switch (type) {
        case 'FULL':
            return SynchronizationType.Full;
        case 'DIFFERENTIAL':
            return SynchronizationType.Differential;
    }
};

const mapSynchronizationStatus = (status: 'PENDING' | 'DISABLED' | 'IN PROGRESS' | 'READY') => {
    switch (status) {
        case 'PENDING':
            return SynchronizationStatus.Pending;
        case 'DISABLED':
            return SynchronizationStatus.Disabled;
        case 'IN PROGRESS':
            return SynchronizationStatus.InProgress;
        case 'READY':
            return SynchronizationStatus.Ready;
    }
};

const createSynchronization = (
    scope: SynchronizationScope,
    result: SynchronizationResult,
): Synchronization => {
    const status = mapSynchronizationStatus(result.status);

    if (status === SynchronizationStatus.Ready || status === SynchronizationStatus.Disabled) {
        return {
            scope,
            status,
            frequency: result.frequency,
            scheduledAt: result.scheduledAt,
            filterAttribute: result.filterAttribute,
            importSecondaryLocales: result.importSecondaryLocales,
            importMarketsPricing: result.importMarketsPricing,
            importPreExistingProductsOnly: result.importPreExistingProductsOnly,
            importAssociations: result.importAssociations,
            importMetafieldAssetCollections: result.importMetafieldAssetCollections,
            publishProductsInChannels: result.publishProductsInChannels,
        };
    }

    const type = mapSynchronizationType(result.type!);

    if (type === SynchronizationType.Full) {
        return {
            scope,
            status,
            type,
            frequency: result.frequency,
            scheduledAt: result.scheduledAt,
            filterAttribute: result.filterAttribute,
            importSecondaryLocales: result.importSecondaryLocales,
            importMarketsPricing: result.importMarketsPricing,
            importPreExistingProductsOnly: result.importPreExistingProductsOnly,
            importAssociations: result.importAssociations,
            importMetafieldAssetCollections: result.importMetafieldAssetCollections,
            publishProductsInChannels: result.publishProductsInChannels,
            createdAt: result.createdAt!,
            id: result.id!,
        };
    }

    return {
        scope,
        status,
        type,
        createdAt: result.createdAt!,
        referenceDate: result.referenceDate!,
        id: result.id!,
        scheduledAt: result.scheduledAt!,
        frequency: result.frequency,
        filterAttribute: result.filterAttribute,
        importSecondaryLocales: result.importSecondaryLocales,
        importMarketsPricing: result.importMarketsPricing,
        importPreExistingProductsOnly: result.importPreExistingProductsOnly,
        importAssociations: result.importAssociations,
        importMetafieldAssetCollections: result.importMetafieldAssetCollections,
        publishProductsInChannels: result.publishProductsInChannels,
    };
};

type SynchronizationResult = {
    status: 'READY' | 'DISABLED' | 'PENDING' | 'IN PROGRESS';
    type: 'FULL' | 'DIFFERENTIAL' | null;
    referenceDate: string | null;
    createdAt: string | null;
    id: string | null;
    scheduledAt: string;
    frequency: string;
    filterAttribute: string | null;
    importSecondaryLocales: boolean | null;
    importMarketsPricing: boolean | null;
    importPreExistingProductsOnly: boolean | null;
    importAssociations: boolean | null;
    importMetafieldAssetCollections: boolean | null;
    publishProductsInChannels: boolean | null;
};

type Result = {
    [key in 'product' | 'productVariant']: SynchronizationResult;
};
export const fetchSynchronizations = (
    currentStoreId: string,
): Promise<{
    [key in SynchronizationScope]: Synchronization;
}> =>
    apiFetch<Result>(`${currentStoreId}/synchronization/retrieve-executing-synchronizations`).then(
        (result) => ({
            [SynchronizationScope.Product]: createSynchronization(
                SynchronizationScope.Product,
                result.product,
            ),
            [SynchronizationScope.ProductVariant]: createSynchronization(
                SynchronizationScope.ProductVariant,
                result.productVariant,
            ),
        }),
    );
