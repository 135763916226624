import { Route, Routes } from 'react-router';
import { ManageSingleMapping } from './ManageSingleMapping';
import { ManageFamilyMapping } from './Product/ProductMappingPerFamily/ManageFamilyMapping';
import { ProductMappingProvider } from './Product/ProductMappingProvider';
import { MediaMapping } from './Product/MediaMapping/MediaMapping';
import { MediaMappingProvider } from './Product/MediaMapping/MediaMappingProvider';
import { MediaMappingScope } from './model/MediaMappingScope';

export const NativeFields = () => (
    <ProductMappingProvider>
        <MediaMappingProvider mediaMappingScope={MediaMappingScope.Product}>
            <Routes>
                <Route path='/' element={<ManageSingleMapping />} />
                <Route path=':shopifyAttributeCode' element={<ManageFamilyMapping />} />
                <Route path='media-mapping' element={<MediaMapping />} />
            </Routes>
        </MediaMappingProvider>
    </ProductMappingProvider>
);
