import React from 'react';
import { capitalize as capitalizeFunc } from 'lodash';
import { useIntl } from 'react-intl';
import { selectPimAttributeTypeFormatted } from '../selector/selectPimAttributeTypeFormatted';

type Props = {
    types: string[];
    capitalize?: boolean;
    sort?: boolean;
};

export const FormattedPimAttributeTypes = ({ types, capitalize = true, sort = true }: Props) => {
    const intl = useIntl();

    // translate all types
    let typesFormatted = types.map((type, key) => {
        const typeFormatted = selectPimAttributeTypeFormatted(type);
        if (typeFormatted !== null) {
            return intl.formatMessage(typeFormatted);
        }

        return type;
    });

    // order by type formatted
    if (sort) {
        typesFormatted.sort((a, b) => a.localeCompare(b));
    }

    // convert types array to string
    let typesFormattedToString = typesFormatted.join(', ');

    // and convert first letter of types to upper case
    if (capitalize) {
        typesFormattedToString = capitalizeFunc(typesFormattedToString);
    }

    return <>{typesFormattedToString}</>;
};
