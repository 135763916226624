import { Helper, Link, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { helpCenterMarketsPricing } from '../../../../util/config';
import { MarketsPricingItem } from './MarketsPricingItem';
import { useSelector } from '../store/StoreProvider';
import { MarketsPricingType } from '../model/MarketsPricingModel';
import styled from 'styled-components';

const ShopifyHelperContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const cellStyle = {
    background: '#f6f7fb',
    padding: 0,
};

const MAX_SELECTED_MARKETS = 50;

export const MarketsPricingMapping = () => {
    const { marketsPricingMapping, allowedShopifyMarkets, badRequestErrorMessage } = useSelector(
        (state) => state,
    );
    return (
        <>
            {null !== badRequestErrorMessage && (
                <Helper level='error'>
                    <FormattedMessage
                        id='PR2F1h'
                        defaultMessage='{message}'
                        values={{
                            message: badRequestErrorMessage,
                        }}
                    />
                </Helper>
            )}
            <>
                <Table>
                    <Table.Header>
                        <Table.HeaderCell>
                            <FormattedMessage id='uDNsU7' defaultMessage='Shopify market' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage id='woitrG' defaultMessage='Shopify currency' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage id='7KloSP' defaultMessage='PIM locale' />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan={4} style={cellStyle}>
                                <ShopifyHelperContainer>
                                    <Helper level='info'>
                                        <FormattedMessage
                                            id='/FwFDV'
                                            defaultMessage='Markets pricing allows you to synchronize international pricing into different Shopify markets, '
                                        />
                                        <Link href={helpCenterMarketsPricing} target='_blank'>
                                            <FormattedMessage
                                                id='+z9ImG'
                                                defaultMessage='check out our Help Center for more information.'
                                            />
                                        </Link>
                                    </Helper>
                                </ShopifyHelperContainer>
                            </Table.Cell>
                        </Table.Row>

                        {marketsPricingMapping.map((marketPricing, index) => {
                            if (marketPricing !== undefined) {
                                return (
                                    <Table.Row key={index}>
                                        <MarketsPricingItem
                                            marketPricing={marketPricing as MarketsPricingType}
                                            index={index}
                                        />
                                    </Table.Row>
                                );
                            }
                            return <></>;
                        })}
                        {/*
                        If there is no more Shopify markets available in the options list allowedShopifyMarkets, it means that all markets are mapped.
                        Therefore, we hide the empty row and there is no more
                         */}
                        {!allowedShopifyMarkets.length ||
                            (marketsPricingMapping.length < MAX_SELECTED_MARKETS &&
                                marketsPricingMapping.slice(-1)[0]?.marketId !== null && (
                                    <Table.Row key={'newMarketPricing'}>
                                        <MarketsPricingItem marketPricing={null} index={null} />
                                    </Table.Row>
                                ))}
                    </Table.Body>
                </Table>

                {marketsPricingMapping.length === MAX_SELECTED_MARKETS && (
                    <Helper level='warning'>
                        <FormattedMessage
                            id='NRWMAH'
                            values={{
                                maxSelectedMarkets: MAX_SELECTED_MARKETS,
                            }}
                            defaultMessage='You have reached the limit of {maxSelectedMarkets} Shopify markets. Please check the Help Center for more information.'
                        />{' '}
                        <Link href={helpCenterMarketsPricing} target='_blank'>
                            <FormattedMessage
                                id='i/qJsQ'
                                defaultMessage='Please check the Help Center for more information.'
                            />
                        </Link>
                    </Helper>
                )}
            </>
        </>
    );
};
