import { apiFetch } from '../../../../util/apiFetch';
import { AssociationMapping } from '../model/MetafieldAssociationModel';

type Result = {
    mapping: AssociationMapping;
};

export const getMetafieldAssociationMappingQueryKey = 'getMetafieldAssociationMapping';

export const getMetafieldAssociationMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/metafield/get-metafield-association-mapping`).then(
        (result) => result,
    );
