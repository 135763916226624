import React, { useState } from 'react';
import { initialState, reducer } from './store/reducer';
import { useCurrentStore } from '../../MultiStore';
import { useQuery } from 'react-query';
import { getShopifyMarkets, getShopifyMarketsQueryKey } from './api/getShopifyMarkets';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../util/InstabilityType';
import { getMarketsPricingMapping } from './api/getMarketsPricingMapping';
import { useGetConnectorConfig } from '../hook/useGetConnectorConfig';
import { getConnectorConfigOptions } from '../api/getConnectorConfigOptions';
import { BadRequestError } from '../../../util/apiFetch';
import { useIntl } from 'react-intl';
import { MarketsPricingType } from './model/MarketsPricingModel';

type Props = {
    children: React.ReactNode;
};
export const MarketsPricingProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { currentStoreId } = useCurrentStore();
    const [shopifyHasInstability, setShopifyHasInstability] = useState<boolean>(false);
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);

    const getConnectorConfigQuery = useGetConnectorConfig();
    const isConfigInvalid = !getConnectorConfigQuery.data;

    const intl = useIntl();

    try {
        useQuery(
            [currentStoreId, getShopifyMarketsQueryKey],
            () =>
                Promise.all([
                    getShopifyMarkets(currentStoreId),
                    getMarketsPricingMapping(currentStoreId),
                    getConnectorConfigOptions(currentStoreId),
                ]),
            {
                enabled: !isConfigInvalid,
                onSuccess: ([shopifyMarkets, marketsPricingMapping, connectorConfigOptions]) => {
                    if (shopifyMarkets.error) {
                        if (
                            typeof shopifyMarkets.error === 'string' &&
                            shopifyMarkets.error === InstabilityType.shopify_instability
                        ) {
                            setShopifyHasInstability(true);
                            return;
                        }

                        if (
                            typeof shopifyMarkets.error !== 'string' &&
                            shopifyMarkets.error?.message
                        ) {
                            dispatch({
                                type: 'marketsPricingMapping/reject',
                                errorMessage: shopifyMarkets.error.message,
                            });
                            return;
                        }
                    }

                    if (
                        marketsPricingMapping.error &&
                        Object.keys(marketsPricingMapping.error).length > 0
                    ) {
                        if (
                            typeof marketsPricingMapping.error === 'string' &&
                            marketsPricingMapping.error === InstabilityType.shopify_instability
                        ) {
                            setShopifyHasInstability(true);
                            return;
                        }

                        if (
                            typeof marketsPricingMapping.error !== 'string' &&
                            marketsPricingMapping.error?.violations
                        ) {
                            dispatch({
                                type: 'marketsPricingMapping/reject',
                                errorMessage: intl.formatMessage({
                                    defaultMessage:
                                        'Some elements in your mapping are no longer available. Please update or remove them.',
                                    id: '2yX+Ae',
                                }),
                            });

                            const violations = marketsPricingMapping.error.violations;
                            let newMarketsMappingToSave: MarketsPricingType[] = [];

                            marketsPricingMapping.mapping.forEach((marketPricing, row) =>
                                newMarketsMappingToSave.push({
                                    marketId: marketPricing.marketId,
                                    marketName: marketPricing.marketName,
                                    currency: marketPricing.currency,
                                    locale: marketPricing.locale,
                                    errors: violations[row],
                                }),
                            );

                            dispatch({
                                type: 'marketsPricingMapping/fetch/collections',
                                marketsPricingMapping: newMarketsMappingToSave,
                            });
                        }
                    } else {
                        dispatch({
                            type: 'marketsPricingMapping/fetch/collections',
                            marketsPricingMapping: marketsPricingMapping.mapping,
                        });
                    }

                    if (
                        connectorConfigOptions.error &&
                        connectorConfigOptions.error === InstabilityType.pim_instability
                    ) {
                        setPimHasInstability(true);
                        return;
                    }

                    if (shopifyMarkets.markets) {
                        dispatch({
                            type: 'shopifyMarkets/fetch/collections',
                            shopifyMarkets: shopifyMarkets.markets,
                        });
                    }
                    dispatch({
                        type: 'pimLocales/fetch',
                        pimLocales: connectorConfigOptions.localeOptions,
                    });
                },
                onError: (
                    error: BadRequestError<{
                        error: { code: string; message: string };
                    }>,
                ) => {
                    dispatch({
                        type: 'marketsPricingMapping/reject',
                        errorMessage: error.data.error.message,
                    });
                },
            },
        );
    } catch (error) {
        if (!(error instanceof BadRequestError)) {
            throw error;
        }
    }

    return (
        <>
            {shopifyHasInstability && <InstabilityStickyHelper source='SHOPIFY' />}
            {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
            </StateContext.Provider>
        </>
    );
};
