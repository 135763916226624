import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { saveVariantMediaMapping } from '../api/saveVariantMediaMapping';
import { MediaMapping } from '../model/MediaMapping';
import { fetchMediaMappingQueryKey } from './useMediaMapping';
import { useToaster } from '../../../../../../util/toast';
import { useCurrentStore } from '../../../../../MultiStore';

export const useSaveMediaMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const queryClient = useQueryClient();

    const { currentStoreId } = useCurrentStore();

    const mutation = useMutation(saveVariantMediaMapping);

    return async (mapping: MediaMapping) =>
        await mutation.mutateAsync(
            { storeId: currentStoreId, mapping },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(fetchMediaMappingQueryKey);

                    addToast(
                        intl.formatMessage({
                            id: 'Uu6zIT',
                            defaultMessage: 'Media mapping successfully updated.',
                        }),
                        'success',
                    );
                },
            },
        );
};
