import { useCurrentStore } from '../../../../../MultiStore';
import { useQuery } from 'react-query';
import { getPimAssetAttributes } from '../../../Product/MediaMapping/api/getPimAssetAttributes';
import { MediaMappingScope } from '../../../model/MediaMappingScope';

type Attributes = Array<{
    code: string;
    label: string;
    mediaAssetAttributes: Array<{
        code: string;
        label: string;
        type: string;
        mediaType: string;
    }>;
    altTextAssetAttributes: Array<{
        code: string;
        label: string;
        type: string;
    }>;
}>;

export const usePimAssetCollectionAttributes = (): {
    isLoading: boolean;
    attributes: Attributes;
} => {
    const { currentStoreId } = useCurrentStore();

    const { isLoading, data } = useQuery('getPimAssetAttributes', () =>
        getPimAssetAttributes(currentStoreId, MediaMappingScope.ProductVariant),
    );

    if (!data) {
        return { isLoading, attributes: [] };
    }

    const attributes = data.assetCollectionAttributes.map((attribute) => ({
        code: attribute.code,
        label: attribute.label,
        mediaAssetAttributes: data.mediaAssetAttributesPerAssetFamily[attribute.familyCode]!,
        altTextAssetAttributes: data.altTextAssetAttributesPerAssetFamily[attribute.familyCode]!,
    }));

    attributes.sort(({ label: a }, { label: b }) => {
        a = a.toUpperCase();
        b = b.toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
    });
    attributes.forEach((attribute) =>
        attribute.mediaAssetAttributes.sort(({ label: a }, { label: b }) => {
            a = a.toUpperCase();
            b = b.toUpperCase();
            return a < b ? -1 : a > b ? 1 : 0;
        }),
    );

    return { isLoading, attributes };
};
