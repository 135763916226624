import { FormattedMessage } from 'react-intl';

type Props = {
    isScheduledSync: boolean;
};

export const SynchronizationScheduledManualLabel = ({ isScheduledSync }: Props) => {
    return isScheduledSync ? (
        <FormattedMessage id='VfB4FS' defaultMessage='Scheduled sync' />
    ) : (
        <FormattedMessage id='zFFdYx' defaultMessage='Manual sync' />
    );
};
