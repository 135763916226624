import { Immutable } from 'immer';
import { apiFetch } from '../../../util/apiFetch';

type MetaObjectMapping = {
    storeId: string;
    data: Immutable<string>;
};

export const saveMetaobjectsMapping = (mapping: MetaObjectMapping) => {
    let data = [];
    try {
        data = JSON.parse(mapping.data);
    } catch (error) {}

    return apiFetch(`${mapping.storeId}/metaobject/save-metaobject-reference-entities-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mapping: data,
        }),
    });
};
