import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    pimAttributes: {
        [code: string]: {
            code: string;
            type: string;
            families: string[];
            label: string;
            localizable: boolean;
            scopable: boolean;
        };
    };
    error?: string;
};

export const getPimAttributes = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/mapping/retrieve-pim-attributes`).then((result) => result);
