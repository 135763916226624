import React from 'react';
import { useQuery } from 'react-query';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { useCurrentStore } from '../../MultiStore';
import { Loading } from '../../../component';
import { getMetafieldAssetCollectionsMapping } from './api/getMetafieldAssetCollectionsMapping';

type Props = {
    children: React.ReactNode;
};

export const MetafieldAssetCollectionsProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { currentStoreId } = useCurrentStore();

    const { isFetching } = useQuery(
        [currentStoreId, 'getMediaMapping'],
        () => getMetafieldAssetCollectionsMapping(currentStoreId),
        {
            onSuccess: (result) => {
                dispatch({
                    type: 'assetMapping/fetch/sourceAndMapping',
                    assetMapping: result.mediaMapping,
                });
            },
        },
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};
