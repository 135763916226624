import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const getElapsedTime = (createdAt: string) => {
    const duration = DateTime.utc().diff(DateTime.fromISO(createdAt), ['hour', 'minute', 'second']);

    if (duration.hours > 0) {
        return duration.toFormat('hh:mm:ss');
    }

    return duration.toFormat('mm:ss');
};

type Props = {
    createdAt: string;
};

export const SynchronizationDuration = ({ createdAt }: Props) => {
    const [duration, setDuration] = useState<string>(getElapsedTime(createdAt));

    useEffect(() => {
        const handle = setInterval(() => {
            setDuration(getElapsedTime(createdAt));
        }, 1000);

        return () => {
            clearInterval(handle);
        };
    }, [createdAt]);

    return <>{duration}</>;
};
