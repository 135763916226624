import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { saveMetaobjectsMapping } from '../api/saveMetaobjectsMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useToaster } from '../../../util/toast';
import { useCurrentStore } from '../../MultiStore';

export const useSaveMetaobjectMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const saveMetaobjectsMappingMutation = useMutation(saveMetaobjectsMapping);
    const { currentStoreId } = useCurrentStore();
    const { metaObjectMapping } = useSelector((state) => state);

    const saveMapping = async () => {
        await saveMetaobjectsMappingMutation.mutateAsync({
            storeId: currentStoreId,
            data: metaObjectMapping,
        });

        dispatch({
            type: 'metaObjectMapping/source/saved',
            mappingIsDirty: false,
        });

        addToast(
            intl.formatMessage({
                id: 'NiH4yF',
                defaultMessage: 'Metaobject mapping successfully updated.',
            }),
            'success',
        );
    };

    return { saveMapping };
};
