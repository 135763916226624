import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useResetLinks } from './hook/useResetLinks';
import React, { useState } from 'react';
import { ResetLinksConfirmationModal } from './component/ResetLinksConfirmationModal';

const ButtonContainer = styled.div`
    padding-left: 10px;
`;

export const ResetLinksButton = () => {
    const { resetAppLinks, isResetting } = useResetLinks();

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleReset = async () => {
        if (!showConfirmation) {
            setShowConfirmation(true);

            return;
        }

        await resetAppLinks();
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <ButtonContainer>
            <Button level={'tertiary'} disabled={isResetting} onClick={handleReset}>
                <FormattedMessage defaultMessage='Reset links' id='CSQiXM' />
            </Button>
            {showConfirmation && (
                <ResetLinksConfirmationModal onConfirm={handleReset} onCancel={handleCancel} />
            )}
        </ButtonContainer>
    );
};
