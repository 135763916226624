import React from 'react';
import { PageContent, PageHeader } from '../../component';

import { Link, Placeholder, UserGroupsIllustration } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

export const PimOauthError = () => {
    const intl = useIntl();

    return (
        <>
            <PageHeader></PageHeader>
            <PageContent>
                <Placeholder
                    illustration={<UserGroupsIllustration />}
                    title={intl.formatMessage({
                        id: 't7/xRB',
                        defaultMessage:
                            'The connection between the Akeneo PIM and Shopify was not successful.',
                    })}
                    size='large'
                >
                    <FormattedMessage
                        id='U1tYC+'
                        defaultMessage='Please go back to the Akeneo PIM and try again. {br}If the problem persists, please contact Akeneo support.'
                        values={{ br: <br /> }}
                    />

                    <Link href='/authentication/from-shopify'>
                        {intl.formatMessage({
                            id: 'QFOInc',
                            defaultMessage: 'Reconnect to the PIM.',
                        })}
                    </Link>
                </Placeholder>
            </PageContent>
        </>
    );
};
