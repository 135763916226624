import { Helper, SectionTitle } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from '../store/StoreProvider';
import { MappingItem } from './component/MappingItem';
import { MappingList } from './component/MappingList';
import { HasAttributePerFamilyToggle } from './HasAttributePerFamilyToggle';
import { PimAttributeCollectionSelector } from './PimAttributeCollectionSelector';
import { PimAttributeSelector } from './PimAttributeSelector';
import { MappingSection } from './component/MappingSection';
import { EditMediaMappingButton } from '../../Component/MediaMapping/EditMediaMappingButton';
import { useNavigate } from 'react-router-dom';

export const ProductMapping = () => {
    const navigate = useNavigate();
    const shopifyAttributes = useSelector((state) => state.shopifyAttributes);
    const shopifyAttributeSections = useSelector((state) => state.shopifyAttributeSections);
    const { pimAttributeErrors } = useSelector((state) => state);

    const endSectionStyle = {
        paddingTop: '40px',
        display: 'block',
    };

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='yZ2ElN' defaultMessage='Simple product fields' />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='EPgn99'
                    defaultMessage='Respecting Shopify business rules is required to guarantee the synchronization.'
                />
            </Helper>

            <MappingList>
                {Object.entries(shopifyAttributeSections).map(([key, values]) => (
                    <React.Fragment key={key}>
                        <MappingSection sectionLabel={key} />
                        {Object.values(values).map((value) => (
                            <MappingItem
                                key={value + shopifyAttributes[value]?.code}
                                shopifyAttribute={shopifyAttributes[value]!}
                            >
                                {shopifyAttributes[value]?.code === 'IMAGES' && (
                                    <div>
                                        <EditMediaMappingButton
                                            onClick={() => navigate('media-mapping')}
                                        />
                                    </div>
                                )}
                                {!shopifyAttributes[value]?.collection && (
                                    <PimAttributeSelector
                                        shopifyAttribute={shopifyAttributes[value]!}
                                        pimAttributeErrors={pimAttributeErrors}
                                    />
                                )}
                                {shopifyAttributes[value]?.collection &&
                                    shopifyAttributes[value]?.code !== 'IMAGES' && (
                                        <PimAttributeCollectionSelector
                                            shopifyAttribute={shopifyAttributes[value]!}
                                        />
                                    )}
                                {shopifyAttributes[value]?.code !== 'IMAGES' && (
                                    <HasAttributePerFamilyToggle
                                        shopifyAttributeCode={shopifyAttributes[value]?.code!}
                                    />
                                )}
                            </MappingItem>
                        ))}
                        <tr style={endSectionStyle} />
                    </React.Fragment>
                ))}
            </MappingList>
        </>
    );
};
