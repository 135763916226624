import { apiFetch } from '../../../../util/apiFetch';
import {
    AltTextAssetAttributesPerAssetFamily,
    MediaAssetAttributesPerAssetFamily,
} from '../../../NativeFields/NativeFields/Product/MediaMapping/model/AssetAttributesPerAssetFamily';
import { AssetCollectionAttributes } from '../../../NativeFields/NativeFields/Product/MediaMapping/model/AssetCollectionAttribute';

type Result = {
    assetCollectionAttributes: AssetCollectionAttributes[];
    mediaAssetAttributesPerAssetFamily: {
        [asset_family_code: string]: MediaAssetAttributesPerAssetFamily[];
    };
    altTextAssetAttributesPerAssetFamily: {
        [asset_family_code: string]: AltTextAssetAttributesPerAssetFamily[];
    };
};

export const getPimAssetAttributes = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/pim-asset-attributes/metafield`).then((result) => result);
