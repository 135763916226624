import { getColor, SectionTitle, Table } from 'akeneo-design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
    sectionLabel: string;
};

const ShopifySectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const cellStyle = {
    background: '#f6f7fb',
};

const sectionTitleStyle = {
    border: 'none',
};

const CustomSectionTitle = styled(SectionTitle.Title)`
    color: ${getColor('brand', 100)};
    font-size: 13px;
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 20px 0px 20px 20px;
`;

export const MappingSection = ({ sectionLabel }: Props) => {
    return (
        <Table.Row>
            <Table.Cell colSpan={4} style={cellStyle}>
                <ShopifySectionContainer>
                    <SectionTitle style={sectionTitleStyle}>
                        <CustomSectionTitle level='secondary'>{sectionLabel}</CustomSectionTitle>
                        <SectionTitle.Spacer />
                    </SectionTitle>
                </ShopifySectionContainer>
            </Table.Cell>
        </Table.Row>
    );
};
