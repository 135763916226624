export const helpCenterUrl = 'https://help.akeneo.com/en_US/akeneo-app-for-shopify';

export const helpCenterMultiStoreUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-multistore-management';

export const helpCenterConfigurationUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-configuration-set-up';

export const helpCenterLogsUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-logs-errors';

export const helpCenterMetafieldsMappingUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-metafields';

export const helpCenterMediaMappingUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-media-mapping';

export const helpCenterMediaMappingHowToMapMediaUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-media-mapping#how-to-map-media';

export const helpCenterAssetCollectionsMetafieldMappingHowToMapMediaUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-asset-collection-mapping';

export const helpCenterMetaobjectsMappingHowToMapMediaUrl =
    'https://help.akeneo.com/shopify-app-features/shopify-app-reference-entities-mapping';

export const helpCenterMultiLocaleManagement =
    'https://help.akeneo.com/en_US/shopify-app-features/shopify-app-multi-locale-management';

export const helpCenterPreExistingCatalog =
    'https://help.akeneo.com/shopify-app-features/shopify-app-pre-existing-catalogs';

export const helpCenterMarketsPricing = 'https://help.akeneo.com/en_US/shopify-app-markets-pricing';

export const helpCenterAssociationsMappingUrl =
    'https://help.akeneo.com/en_US/shopify-app-associations';

export const helpCenterProductPublishing =
    'https://help.akeneo.com/shopify-app-features/shopify-app-publish-products';

export const supportUrl =
    'https://akeneo.atlassian.net/servicedesk/customer/portal/8/group/28/create';

export const akeneoPimDemoUrl = 'https://www.akeneo.com/demo/';

export const pimAuthenticationUrl = function (pimURL: string) {
    return `${process.env.REACT_APP_API_WEB_PATH}/authentication/activate?pim_url=${pimURL}`;
};

export const shopifyAppStoreUrl = 'https://apps.shopify.com/akeneo-pim-app';

export const shopifyAuthenticationUrl = function (shopifyShop: string) {
    return `${process.env.REACT_APP_API_WEB_PATH}/shopify/?shop=${shopifyShop}`;
};
