import { Immutable, produce } from 'immer';
import {
    ActivatedShopifyLanguages,
    PimEnabledLocales,
    SecondaryLocalesMapped,
    SecondaryLocalesMapping,
} from '../model/SecondaryLocalesModel';

export type State = Immutable<{
    activatedShopifyLanguages: ActivatedShopifyLanguages;
    allowedShopifyLocales: ActivatedShopifyLanguages;

    mainLocale: string | null;
    pimEnabledLocales: PimEnabledLocales;
    allowedPimLocales: PimEnabledLocales;

    secondaryLocalesMapping: SecondaryLocalesMapping;
    secondaryLocalesMapped: SecondaryLocalesMapped;

    mappingIsDirty: boolean;
}>;

export const initialState: State = {
    activatedShopifyLanguages: [],
    allowedShopifyLocales: [],

    mainLocale: null,
    pimEnabledLocales: [],
    allowedPimLocales: [],

    secondaryLocalesMapping: [],
    secondaryLocalesMapped: {},

    mappingIsDirty: false,
};

export type Action =
    | {
          type: 'secondaryLocales/fetch/collections';
          activatedShopifyLanguages: ActivatedShopifyLanguages;
          pimEnabledLocales: PimEnabledLocales;
      }
    | {
          type: 'secondaryLocalesMapping/fetch';
          secondaryLocalesMapping: SecondaryLocalesMapping;
      }
    | {
          type: 'secondaryLocalesMapping/new';
          newShopifyLocale: string | null;
          newShopifyLabel: string | null;
          newPimLocale: string | null;
      }
    | {
          type: 'secondaryLocalesMapping/update';
          index: number;
          newShopifyLocale: string | null;
          newShopifyLabel: string | null;
          newPimLocale: string | null;
      }
    | {
          type: 'secondaryLocalesMapped/fetch';
          secondaryLocalesMapped: SecondaryLocalesMapped;
      }
    | {
          type: 'secondaryLocalesMapping/remove';
          secondaryLocaleToRemove: string;
      }
    | {
          type: 'secondaryLocales/saved';
          mappingIsDirty: boolean;
      }
    | {
          type: 'mainLocale/update';
          newMainLocale: string | null;
      };

export const reducer = produce<(draft: State, action: Action) => State>((draft, action) => {
    switch (action.type) {
        case 'secondaryLocales/fetch/collections':
            draft.activatedShopifyLanguages = action.activatedShopifyLanguages;
            draft.pimEnabledLocales = action.pimEnabledLocales;
            break;
        case 'secondaryLocalesMapping/fetch':
            draft.secondaryLocalesMapping = action.secondaryLocalesMapping;
            break;
        case 'secondaryLocalesMapping/new':
            draft.secondaryLocalesMapping.push({
                shopifyKey: action.newShopifyLocale,
                shopifyLabel: action.newShopifyLabel,
                pimValue: action.newPimLocale,
            });
            draft.mappingIsDirty = true;
            break;
        case 'secondaryLocalesMapping/update':
            draft.secondaryLocalesMapping[action.index]!.shopifyKey = action.newShopifyLocale;
            draft.secondaryLocalesMapping[action.index]!.shopifyLabel = action.newShopifyLabel;
            draft.secondaryLocalesMapping[action.index]!.pimValue = action.newPimLocale;
            draft.mappingIsDirty = true;
            break;
        case 'secondaryLocalesMapped/fetch':
            draft.secondaryLocalesMapping = [];
            Object.entries(action.secondaryLocalesMapped).forEach(([shopifyKey, pimValue]) => {
                draft.secondaryLocalesMapping.push({
                    shopifyKey: shopifyKey,
                    shopifyLabel:
                        draft.activatedShopifyLanguages.find((elem) => elem.language === shopifyKey)
                            ?.name ?? null,
                    pimValue: pimValue,
                });
            });

            break;
        case 'secondaryLocalesMapping/remove':
            draft.secondaryLocalesMapping = draft.secondaryLocalesMapping.filter(
                (value) => value.shopifyKey !== action.secondaryLocaleToRemove,
            );
            draft.secondaryLocalesMapping.filter(
                (value) => value.pimValue !== action.secondaryLocaleToRemove,
            );
            draft.mappingIsDirty = true;
            break;
        case 'secondaryLocales/saved':
            draft.mappingIsDirty = false;
            break;
        case 'mainLocale/update':
            draft.mainLocale = action.newMainLocale;
            break;
    }

    draft.allowedShopifyLocales = draft.activatedShopifyLanguages?.filter(
        (allowedShopifyLocale) =>
            !draft.secondaryLocalesMapping.find(
                (mapping) => mapping.shopifyKey === allowedShopifyLocale.language,
            ),
    );

    draft.allowedPimLocales = draft.pimEnabledLocales?.filter(
        (allowedPimLocale) =>
            !draft.secondaryLocalesMapping.find(
                (mapping) => mapping.pimValue === allowedPimLocale.code,
            ) && allowedPimLocale.code !== draft.mainLocale,
    );

    return draft;
});
