import React from 'react';
import { State, Action, reducer } from './reducer';

const StateContext = React.createContext<State | undefined>(undefined);

const DispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

export const StoreProvider = ({
    children,
    initialState,
}: {
    children: React.ReactNode;
    initialState: State;
}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export const useSelector = <T extends unknown>(selector: (state: State) => T) => {
    const state = React.useContext(StateContext);
    if (state === undefined) {
        throw new Error();
    }

    return selector(state);
};

export const useDispatch = () => {
    const dispatch = React.useContext(DispatchContext);
    if (dispatch === undefined) {
        throw new Error();
    }

    return dispatch;
};
