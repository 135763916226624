import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isEmpty: boolean;
};

export const isMarketsPricingMappingEmpty = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/synchronization/is-markets-pricing-mapping-empty`).then(
        (result) => result,
    );
