import { Immutable, produce } from 'immer';
import { DateTime } from 'luxon';
import { SynchronizationStatus } from '../model/SynchronizationStatus';
import { dateTimeToIso8601 } from '../../../util/dateTimeToIso8601';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { Synchronization } from '../model/Synchronization';
import { SynchronizationType } from '../model/SynchronizationType';

export type Filters = {
    method: 'FULL' | 'DIFFERENTIAL';
    updated: string;
    medias: boolean;
    metafields: boolean;
    associations: boolean;
    metafieldAssetCollections: boolean;
    secondaryLocales: boolean;
    marketsPricing: boolean;
    preExistingProductsOnly: boolean;
    publishingChannels: boolean;
};

export type State = Immutable<{
    filters: Filters;
    synchronizations: {
        [key in SynchronizationScope]: Synchronization;
    };
}>;

export const initialState: State = {
    filters: {
        method: 'FULL',
        updated: dateTimeToIso8601(DateTime.utc()),
        medias: true,
        metafields: true,
        associations: true,
        metafieldAssetCollections: true,
        secondaryLocales: true,
        marketsPricing: true,
        preExistingProductsOnly: true,
        publishingChannels: true,
    },
    synchronizations: {
        [SynchronizationScope.Product]: {
            scope: SynchronizationScope.Product,
            status: SynchronizationStatus.Disabled,
            frequency: 'never',
            scheduledAt: null,
            filterAttribute: null,
            importSecondaryLocales: true,
            importMarketsPricing: true,
            importPreExistingProductsOnly: true,
            importAssociations: true,
            importMetafieldAssetCollections: true,
            publishProductsInChannels: true,
        },
        [SynchronizationScope.ProductVariant]: {
            scope: SynchronizationScope.ProductVariant,
            status: SynchronizationStatus.Disabled,
            frequency: 'never',
            scheduledAt: null,
            filterAttribute: null,
            importSecondaryLocales: true,
            importMarketsPricing: true,
            importPreExistingProductsOnly: true,
            importAssociations: true,
            importMetafieldAssetCollections: true,
            publishProductsInChannels: true,
        },
    },
};

export type Action =
    | { type: 'filters/changed'; payload: Filters }
    | {
          type: 'synchronization/fetchSynchronizations/fulfilled';
          payload: { [key in SynchronizationScope]: Synchronization };
      }
    | {
          type: 'synchronization/launchSynchronization/pending';
          payload: {
              scope: SynchronizationScope;
              createdAt: string;
              referenceDate: string | null;
          };
      }
    | {
          type: 'synchronization/stopSynchronization/pending';
          payload: { scope: SynchronizationScope };
      };

export const reducer = produce<(draft: State, action: Action) => State>((draft, action) => {
    switch (action.type) {
        case 'filters/changed':
            draft.filters = action.payload;
            break;

        case 'synchronization/fetchSynchronizations/fulfilled':
            draft.synchronizations = action.payload;
            break;

        case 'synchronization/launchSynchronization/pending':
            Object.values(draft.synchronizations).forEach((synchronization) => {
                if (synchronization.scope === action.payload.scope) {
                    synchronization.status = SynchronizationStatus.Pending;
                    synchronization.type = SynchronizationType.Full;
                    synchronization.createdAt = action.payload.createdAt;
                    if (action.payload.referenceDate) {
                        synchronization.type = SynchronizationType.Differential;
                        synchronization.referenceDate = action.payload.referenceDate;
                    }
                } else {
                    synchronization.status = SynchronizationStatus.Disabled;
                }
            });
            break;
        case 'synchronization/stopSynchronization/pending':
            Object.values(draft.synchronizations).forEach(
                (synchronization) =>
                    (synchronization.status =
                        synchronization.scope === action.payload.scope
                            ? SynchronizationStatus.Stopping
                            : SynchronizationStatus.Disabled),
            );
            break;
    }

    return draft;
});
