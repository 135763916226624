import { AssociationTypesIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

export const NoPimAssociation = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'd23Nzr',
        defaultMessage: 'There is no association',
    });

    return (
        <>
            <Placeholder
                illustration={<AssociationTypesIllustration />}
                size='large'
                title={title}
            />
        </>
    );
};
