import { Field, MultiSelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import { PublishingChannel } from '../model/PublishingChannel';
import { useDispatch } from '../../store/StoreProvider';

type Props = {
    selectedPublishingChannel: PublishingChannel[];
    publishingChannels: PublishingChannel[];
};

export const SalesChannelsMultiSelect: FC<Props> = ({
    selectedPublishingChannel,
    publishingChannels,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleChange = (selection: string[]) => {
        dispatch({
            type: 'connectorConfigForm/ProductPublishing/SalesChannels/changed',
            salesChannels: publishingChannels.filter((publishingChannel) =>
                selection.includes(publishingChannel.publication_id),
            ),
        });
    };

    return (
        <Field
            fullWidth={true}
            label={intl.formatMessage({
                defaultMessage: 'Sales channel',
                id: 'Y6UOot',
            })}
        >
            <MultiSelectInput
                value={selectedPublishingChannel.map(
                    (publishingChannel) => publishingChannel.publication_id,
                )}
                emptyResultLabel={intl.formatMessage({
                    defaultMessage: 'No sales channel found',
                    id: 'NRTxI9',
                })}
                placeholder={intl.formatMessage({
                    id: 'JDBRaK',
                    defaultMessage: 'Select Sales channels',
                })}
                onChange={handleChange}
                data-testid='value'
                openLabel=''
                removeLabel=''
                readOnly={false}
            >
                {publishingChannels.map((publishingChannel) => (
                    <MultiSelectInput.Option
                        key={publishingChannel.publication_id}
                        title={publishingChannel.catalog_title}
                        value={publishingChannel.publication_id}
                    >
                        {publishingChannel.catalog_title}
                    </MultiSelectInput.Option>
                ))}
            </MultiSelectInput>
        </Field>
    );
};
