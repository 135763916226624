import { AssetMapping, AssetType } from '../model/AssetMapping';
import { MediaAssetAttributesPerAssetFamily } from '../model/AssetAttributesPerAssetFamily';

export function getRemainingMediaAttributes(
    mapping: AssetMapping,
    mediaAttributes: Map<string, MediaAssetAttributesPerAssetFamily[]>,
) {
    let alreadyMappedAttributes: Map<string, string[]> = new Map();

    mapping.forEach((asset: AssetType) => {
        if (!alreadyMappedAttributes.has(asset.attribute_code)) {
            alreadyMappedAttributes.set(asset.attribute_code, []);
        }
        if (asset.asset_attribute_code !== '') {
            alreadyMappedAttributes.get(asset.attribute_code)?.push(asset.asset_attribute_code);
        }
    });

    alreadyMappedAttributes.forEach((assetAttributeCodes: string[], attributeCode: string) => {
        const remaining = mediaAttributes
            .get(attributeCode)
            ?.filter((media: MediaAssetAttributesPerAssetFamily) => {
                return !assetAttributeCodes.includes(media.code);
            });

        mediaAttributes.set(attributeCode, remaining || []);
    });

    return mediaAttributes;
}
