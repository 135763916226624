import { Immutable, produce } from 'immer';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { ConnectorConfigOptions } from '../model/ConnectorConfigOptions';
import { ConnectorConfigForm } from '../model/ConnectorConfigForm';
import { ConnectorConfigErrors } from '../model/ConnectorConfigErrors';
import { isEqual } from 'lodash';
import { PreExistingCatalogIdentifierValues } from '../model/PreExistingCatalogIdentifierValues';
import { PublishingChannel } from '../PublishingSettings/model/PublishingChannel';

export type State = Immutable<{
    connectorConfigForm: ConnectorConfigForm;
    connectorConfigOptions: ConnectorConfigOptions | null;
    preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues | null;
}>;

export const initialState: State = {
    connectorConfigForm: {
        defaultData: null,
        data: null,
        errors: {},
        isDirty: false,
    },
    connectorConfigOptions: null,
    preExistingCatalogIdentifierValues: null,
};

const defaultConnectorConfig: ConnectorConfig = {
    channel: null,
    locale: null,
    categoryCode: '',
    currency: null,
    preExistingCatalogMapping: {
        product_shopify_reference_field: null,
        product_pim_product_identifier: null,
        variant_shopify_reference_field: null,
        variant_pim_product_identifier: null,
    },
    publishingSettings: {
        salesChannels: [],
        b2bCatalogs: [],
    },
};

export type Action =
    | {
          type: 'fetchConfiguration/fulfilled';
          payload: {
              connectorConfig: ConnectorConfig | null;
          };
      }
    | {
          type: 'getConnectorConfigOptions/fulfilled';
          connectorConfigOptions: ConnectorConfigOptions;
      }
    | {
          type: 'getPreExistingCatalogIdentifierValues/fulfilled';
          preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues;
      }
    | {
          type: 'connectorConfigForm/changed';
          connectorConfig: ConnectorConfig;
      }
    | {
          type: 'connectorConfigForm/reset';
      }
    | {
          type: 'saveConnectorConfig/fulfilled';
      }
    | {
          type: 'saveConnectorConfig/rejected';
          errors: ConnectorConfigErrors;
      }
    | {
          type: 'connectorConfigForm/ProductPublishing/SalesChannels/changed';
          salesChannels: PublishingChannel[];
      }
    | {
          type: 'connectorConfigForm/ProductPublishing/B2BCatalogs/changed';
          b2bCatalogs: PublishingChannel[];
      };

export const reducer = produce<(draft: State, action: Action) => State>((draft, action) => {
    switch (action.type) {
        case 'fetchConfiguration/fulfilled':
            if (Object.keys(draft.connectorConfigForm.errors).length === 0) {
                draft.connectorConfigForm = {
                    defaultData: action.payload.connectorConfig,
                    data: action.payload.connectorConfig,
                    errors: {},
                    isDirty: false,
                };
            }

            break;

        case 'getConnectorConfigOptions/fulfilled':
            draft.connectorConfigOptions = action.connectorConfigOptions;
            break;

        case 'getPreExistingCatalogIdentifierValues/fulfilled':
            draft.preExistingCatalogIdentifierValues = action.preExistingCatalogIdentifierValues;
            break;

        case 'connectorConfigForm/changed':
            draft.connectorConfigForm.data = action.connectorConfig;
            draft.connectorConfigForm.isDirty = !isEqual(
                action.connectorConfig,
                draft.connectorConfigForm.defaultData,
            );
            break;

        case 'connectorConfigForm/reset':
            draft.connectorConfigForm = {
                defaultData: null,
                data: null,
                errors: {},
                isDirty: false,
            };
            break;

        case 'saveConnectorConfig/fulfilled':
            draft.connectorConfigForm = {
                defaultData: draft.connectorConfigForm.data,
                data: draft.connectorConfigForm.data,
                errors: {},
                isDirty: false,
            };
            break;
        case 'saveConnectorConfig/rejected':
            draft.connectorConfigForm.errors = action.errors;
            break;

        case 'connectorConfigForm/ProductPublishing/SalesChannels/changed':
            if (draft.connectorConfigForm.data === null) {
                draft.connectorConfigForm.data = defaultConnectorConfig;
            }
            draft.connectorConfigForm.data.publishingSettings.salesChannels = action.salesChannels;
            draft.connectorConfigForm.isDirty = !isEqual(
                action.salesChannels,
                draft.connectorConfigForm.defaultData?.publishingSettings.salesChannels,
            );

            break;
        case 'connectorConfigForm/ProductPublishing/B2BCatalogs/changed':
            if (draft.connectorConfigForm.data === null) {
                draft.connectorConfigForm.data = defaultConnectorConfig;
            }
            draft.connectorConfigForm.data.publishingSettings.b2bCatalogs = action.b2bCatalogs;
            draft.connectorConfigForm.isDirty = !isEqual(
                action.b2bCatalogs,
                draft.connectorConfigForm.defaultData?.publishingSettings.b2bCatalogs,
            );

            break;
    }

    return draft;
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const connectorConfigDeepCopy = (state: any): ConnectorConfig =>
    JSON.parse(JSON.stringify(state));
