import { RunningSynchronizationGuard } from '../Synchronization';
import { DisabledMapping } from '../NativeFields/component/DisabledMapping';
import { ValidConfigurationGuard } from '../Configuration';
import { InvalidConfiguration } from '../NativeFields/component/InvalidConfiguration';
import React from 'react';
import { MetaobjectsProvider } from './MetaobjectsProvider';
import { Route, Routes } from 'react-router-dom';
import { MetaobjectForm } from './component/MetaobjectForm';

const MAX_META_OBJECTS = 10;

export const MetaobjectsTab = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(isConfigInvalid) => (
                    <InvalidConfiguration isConfigInvalid={isConfigInvalid} />
                )}
            >
                <MetaobjectsProvider>
                    <Routes>
                        <Route
                            path='*'
                            element={<MetaobjectForm maxMetaObjects={MAX_META_OBJECTS} />}
                        />
                    </Routes>
                </MetaobjectsProvider>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
