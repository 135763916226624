import { CloseIcon, IconButton, SelectInput, Table } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { SecondaryLocalesMappingType } from '../model/SecondaryLocalesModel';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    index: number | null;
    localesMapping: SecondaryLocalesMappingType | null;
};

const LocaleSelectContainer = styled.div`
    width: 350px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

export const SecondaryLocalesItem = ({ index, localesMapping }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { allowedShopifyLocales, allowedPimLocales } = useSelector((state) => state);

    const handleChange = (
        newShopifyLocale: string | null,
        newShopifyLabel: string | null,
        newPimLocale: string | null,
        index: number | null,
    ) => {
        if (localesMapping === null) {
            dispatch({
                type: 'secondaryLocalesMapping/new',
                newShopifyLocale: newShopifyLocale ?? null,
                newShopifyLabel: newShopifyLabel ?? null,
                newPimLocale: newPimLocale ?? null,
            });
        } else {
            dispatch({
                type: 'secondaryLocalesMapping/update',
                index: index ?? 0,
                newShopifyLocale: newShopifyLocale ?? null,
                newShopifyLabel: newShopifyLabel ?? null,
                newPimLocale: newPimLocale ?? null,
            });
        }
    };

    const handleRemove = () => {
        if (localesMapping!.pimValue !== null && localesMapping!.shopifyKey) {
            dispatch({
                type: 'secondaryLocalesMapping/remove',
                secondaryLocaleToRemove: localesMapping!.shopifyKey,
            });
        }
    };

    return (
        <>
            <Table.Cell>
                <LocaleSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'TQDvLo',
                            defaultMessage: 'No Shopify language to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'qpw0DV',
                            defaultMessage: 'Select a Shopify language',
                        })}
                        value={localesMapping?.shopifyLabel ?? null}
                        openLabel={''}
                        onChange={(newShopifyLocale: string | null) =>
                            handleChange(
                                newShopifyLocale,
                                allowedShopifyLocales.find((el) => el.language === newShopifyLocale)
                                    ?.name ?? null,
                                localesMapping?.pimValue ?? null,
                                index,
                            )
                        }
                        clearable={false}
                    >
                        {Array.from(allowedShopifyLocales.values())
                            .sort((i1, i2) => (i1.name > i2.name ? 1 : -1))
                            .map((shopifyLocale) => (
                                <SelectInput.Option
                                    key={shopifyLocale.name}
                                    value={shopifyLocale.language}
                                >
                                    {shopifyLocale.name}
                                </SelectInput.Option>
                            ))}
                    </SelectInput>
                </LocaleSelectContainer>
            </Table.Cell>
            <Table.Cell>
                <LocaleSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'qbtmRo',
                            defaultMessage: 'No PIM locale to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'GQbx9l',
                            defaultMessage: 'Select a PIM locale',
                        })}
                        value={localesMapping?.pimValue ?? null}
                        openLabel={''}
                        onChange={(newPimLocale: string | null) =>
                            handleChange(
                                localesMapping?.shopifyKey ?? null,
                                localesMapping?.shopifyLabel ?? null,
                                newPimLocale,
                                index,
                            )
                        }
                        clearable={false}
                    >
                        {Array.from(allowedPimLocales.values())
                            .sort((i1, i2) => (i1.code > i2.code ? 1 : -1))
                            .map((pimLocale) => (
                                <SelectInput.Option key={pimLocale.code} value={pimLocale.code}>
                                    {pimLocale.code}
                                </SelectInput.Option>
                            ))}
                    </SelectInput>
                </LocaleSelectContainer>
            </Table.Cell>
            {localesMapping !== null &&
            localesMapping.pimValue !== null &&
            localesMapping.shopifyKey !== null ? (
                <Table.Cell>
                    <RemoveButtonContainer>
                        <IconButton
                            icon={<CloseIcon />}
                            title={intl.formatMessage({
                                id: 'S53Eji',
                                defaultMessage: 'Remove attribute',
                            })}
                            onClick={() => handleRemove()}
                            ghost='borderless'
                            level='tertiary'
                        />
                    </RemoveButtonContainer>
                </Table.Cell>
            ) : (
                <Table.Cell />
            )}
        </>
    );
};
