import { Button, getFontSize, Modal, StatusIllustration } from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const Description = styled.div`
    font-size: ${getFontSize('bigger')};
`;

type Props = {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
};

export const ResetLinksConfirmationModal = ({ onConfirm, onCancel }: Props) => {
    const intl = useIntl();

    const [disableButtons, setDisableButtons] = useState(false);

    const handleConfirm = async () => {
        setDisableButtons(true);
        await onConfirm();
        setDisableButtons(false);
    };

    return (
        <Modal
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            onClose={() => !disableButtons && onCancel()}
            illustration={<StatusIllustration />}
        >
            <Modal.Title>
                <FormattedMessage
                    defaultMessage='Do you want to reset all links in your store?'
                    id='uyj1x8'
                />
            </Modal.Title>

            <Description>
                <FormattedMessage
                    id='nli4WO'
                    defaultMessage='Synchronization issues between Akeneo PIM and your Shopify store may occur following your modifications.'
                />
                <br />
                <FormattedMessage
                    id='gATZ8D'
                    defaultMessage='The data persistence stored in the App will be erased.'
                />
                <br />
                <FormattedMessage
                    id='8a3k1G'
                    defaultMessage='Please clean up your Shopify data before launching a new synchronization.'
                />
            </Description>

            <Modal.BottomButtons>
                <Button level='danger' onClick={onCancel} disabled={disableButtons}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={handleConfirm} disabled={disableButtons}>
                    <FormattedMessage id='N2IrpM' defaultMessage='Confirm' />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};
