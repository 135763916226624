import { Immutable } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { useCurrentStore } from '../../../../MultiStore';
import { Data, saveProductVariantMapping } from '../../api/saveProductVariantMapping';

export const useSaveVariantMappingForm = () => {
    const { currentStoreId } = useCurrentStore();

    return (mapping: Immutable<Map<string, AttributeMapping>>) => {
        const data = Array.from(mapping.values()).reduce<Data>((data, attributeMapping) => {
            let dataValue;
            if (attributeMapping.hasCollectionOfAttribute) {
                dataValue = attributeMapping.hasAttributePerFamily
                    ? attributeMapping.pimAttributeCodesPerFamily
                    : attributeMapping.pimAttributeCodes;
            } else {
                dataValue = attributeMapping.hasAttributePerFamily
                    ? attributeMapping.pimAttributeCodePerFamily
                    : attributeMapping.pimAttributeCode;
            }

            if (null !== dataValue) {
                data[attributeMapping.shopifyAttributeCode] = {
                    hasAttributePerFamily: attributeMapping.hasAttributePerFamily,
                    hasCollectionOfAttribute: attributeMapping.hasCollectionOfAttribute,
                    data: dataValue,
                };
            }
            return data;
        }, {});

        return saveProductVariantMapping(data, currentStoreId);
    };
};
