import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { saveMediaMapping as saveMediaMappingRequest } from '../api/saveMediaMapping';
import { useToaster } from '../../../../../../util/toast';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useCurrentStore } from '../../../../../MultiStore';
import { MediaSource } from '../../../model/MediaSource';

export const useSaveMediaMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const saveMediaMappingMutation = useMutation(saveMediaMappingRequest);
    const { currentStoreId } = useCurrentStore();
    const { mediaSource, mediaMapping, assetMapping, mediaMappingScope } = useSelector(
        (state) => state,
    );

    const dispatch = useDispatch();
    const saveMediaMapping = async () => {
        await saveMediaMappingMutation.mutateAsync({
            mediaMappingScope: mediaMappingScope,
            storeId: currentStoreId,
            mediaSource: mediaSource,
            data: mediaSource === MediaSource.Images ? mediaMapping : assetMapping,
        });
        dispatch({
            type: 'mediaSource/source/saved',
            mappingIsDirty: false,
        });
        addToast(
            intl.formatMessage({
                id: 'Uu6zIT',
                defaultMessage: 'Media mapping successfully updated.',
            }),
            'success',
        );
    };

    return { saveMediaMapping };
};
