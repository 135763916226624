import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SynchronizationScope } from '../model/SynchronizationScope';

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

type Props = {
    scope: SynchronizationScope;
    colored?: boolean;
};

export const SynchronizationScopeLabel = ({ scope, colored }: Props) => {
    let label;
    switch (scope) {
        case SynchronizationScope.Product:
            label = <FormattedMessage id='7NFfmz' defaultMessage='Products' />;
            break;
        case SynchronizationScope.ProductVariant:
            label = <FormattedMessage id='QE0Qxn' defaultMessage='Products with variants' />;
            break;
    }

    return colored ? <ColoredLabel>{label}</ColoredLabel> : label;
};
