import { Immutable, produce } from 'immer';
import { AssetMapping } from '../model/AssetMapping';
import {
    AltTextAssetAttributesPerAssetFamily,
    MediaAssetAttributesPerAssetFamily,
} from '../model/AssetAttributesPerAssetFamily';
import { AssetCollectionAttributes } from '../model/AssetCollectionAttribute';
import { getRemainingMediaAttributes } from './util';

export type State = Immutable<{
    assetMapping: AssetMapping;

    // Default that contain all available values
    mediaAssetCollectionAttributes: Map<string, AssetCollectionAttributes>;
    mediaAssetAttributesPerAssetFamily: Map<string, MediaAssetAttributesPerAssetFamily[]>;
    altTextAssetAttributesPerAssetFamily: Map<string, AltTextAssetAttributesPerAssetFamily[]>;
    // Actual state update after each user action
    allowedMediaAssetAttributesPerAssetFamily: Map<string, MediaAssetAttributesPerAssetFamily[]>;

    mappingIsDirty: boolean;
}>;

export const initialState: State = {
    assetMapping: [],
    mediaAssetCollectionAttributes: new Map(),
    mediaAssetAttributesPerAssetFamily: new Map(),
    altTextAssetAttributesPerAssetFamily: new Map(),
    allowedMediaAssetAttributesPerAssetFamily: new Map(),
    mappingIsDirty: false,
};

export type Action =
    | {
          type: 'assetMapping/fetch/sourceAndMapping';
          assetMapping: AssetMapping;
      }
    | {
          type: 'assetMapping/fetch/collections';
          assetCollectionAttributes: AssetCollectionAttributes[];
      }
    | {
          type: 'assetMapping/fetch/attributes';
          mediaAssetAttributesPerAssetFamily: {
              [asset_family_code: string]: MediaAssetAttributesPerAssetFamily[];
          };
          altTextAssetAttributesPerAssetFamily: {
              [asset_family_code: string]: AltTextAssetAttributesPerAssetFamily[];
          };
      }
    | {
          type: 'assetMapping/mapping/new';
          newAttribute: string;
      }
    | {
          type: 'assetMapping/source/saved';
          mappingIsDirty: boolean;
      }
    | {
          type: 'assetMapping/mapping/update';
          index: number;
          previousValue: string;
          newValue: string;
          newMediaAssetAttribute?: string | null;
          newAltTextAssetAttribute?: string | null;
      }
    | {
          type: 'assetMapping/mapping/remove';
          assetCollectionToRemove: string;
          assetAttributeToRemove: string | null;
      };

export const reducer = produce<(state: State, action: Action) => State>((state, action) => {
    switch (action.type) {
        case 'assetMapping/source/saved':
            state.mappingIsDirty = action.mappingIsDirty;
            break;
        case 'assetMapping/fetch/sourceAndMapping':
            state.assetMapping = action.assetMapping;
            state.mappingIsDirty = false;
            break;
        case 'assetMapping/fetch/collections':
            state.mediaAssetCollectionAttributes = new Map(
                action.assetCollectionAttributes.map((assetCollectionAttributes) => [
                    assetCollectionAttributes.code,
                    assetCollectionAttributes,
                ]),
            );
            break;
        case 'assetMapping/fetch/attributes':
            state.mediaAssetAttributesPerAssetFamily = new Map(
                Object.entries(action.mediaAssetAttributesPerAssetFamily).map(
                    ([assetFamilyCode, assetAttributes]) => [assetFamilyCode, assetAttributes],
                ),
            );

            state.altTextAssetAttributesPerAssetFamily = new Map(
                Object.entries(action.altTextAssetAttributesPerAssetFamily).map(
                    ([assetFamilyCode, assetAttributes]) => [assetFamilyCode, assetAttributes],
                ),
            );
            break;
        case 'assetMapping/mapping/new':
            state.assetMapping.push({
                attribute_code: action.newAttribute,
                asset_attribute_code: '',
                asset_attribute_code_alt_text: '',
            });

            state.mappingIsDirty = false;
            break;
        case 'assetMapping/mapping/update':
            state.assetMapping[action.index]!.attribute_code = action.newValue;

            if ('newMediaAssetAttribute' in action) {
                state.assetMapping[action.index]!.asset_attribute_code =
                    action.newMediaAssetAttribute ? action.newMediaAssetAttribute : '';
            }

            if ('newAltTextAssetAttribute' in action) {
                state.assetMapping[action.index]!.asset_attribute_code_alt_text =
                    action.newAltTextAssetAttribute ? action.newAltTextAssetAttribute : '';
            }

            state.mappingIsDirty = false;
            if (null !== action.newMediaAssetAttribute) {
                state.mappingIsDirty = true;
            }
            break;
        case 'assetMapping/mapping/remove':
            state.assetMapping = state.assetMapping.filter(
                (value) =>
                    value.attribute_code !== action.assetCollectionToRemove ||
                    value.asset_attribute_code !== action.assetAttributeToRemove,
            );
            state.mappingIsDirty = true;
            break;
    }

    state.allowedMediaAssetAttributesPerAssetFamily = new Map(
        state.mediaAssetAttributesPerAssetFamily,
    );
    state.allowedMediaAssetAttributesPerAssetFamily = getRemainingMediaAttributes(
        state.assetMapping,
        state.allowedMediaAssetAttributesPerAssetFamily,
    );

    // Asset mapping
    state.assetMapping.forEach((asset) => {
        if (!asset.asset_attribute_code) {
            state.mappingIsDirty = false;
        }
    });

    return state;
});
