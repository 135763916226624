import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationHistory } from '../model/SynchronizationHistory';
import { SynchronizationHistoryStatus } from '../model/SynchronizationHistoryStatus';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { SynchronizationType } from '../model/SynchronizationType';

type ApiScope = 'CATEGORY' | 'PRODUCT' | 'PRODUCT_VARIANT';

type Result = {
    synchronizationHistory: Array<{
        id: string;
        createdAt: string;
        endedAt: string;
        type: 'FULL' | 'DIFFERENTIAL';
        referenceDate: string;
        scope: ApiScope;
        status: SynchronizationHistoryStatus;
        warningCount: number;
        errorCount: number;
        isScheduledSync: boolean;
        reportVersion: number;
    }>;
};

function scopeToSynchronizationType(scope: ApiScope): SynchronizationScope {
    switch (scope) {
        case 'PRODUCT_VARIANT':
            return SynchronizationScope.ProductVariant;
    }
    return SynchronizationScope.Product;
}

export const fetchSynchronizationHistories = (
    currentStoreId: string,
): Promise<SynchronizationHistory[]> =>
    apiFetch<Result>(`${currentStoreId}/synchronization/retrieve-synchronization-history`).then(
        (result) =>
            result.synchronizationHistory.map((data) => {
                const synchronizationHistory: Partial<SynchronizationHistory> = {
                    id: data.id,
                    createdAt: data.createdAt,
                    endedAt: data.endedAt,
                    scope: scopeToSynchronizationType(data.scope),
                    type:
                        data.type === 'FULL'
                            ? SynchronizationType.Full
                            : SynchronizationType.Differential,
                    status: data.status,
                    isScheduledSync: data.isScheduledSync,
                    reportVersion: data.reportVersion,
                };
                if (synchronizationHistory.type === SynchronizationType.Differential) {
                    synchronizationHistory.referenceDate = data.referenceDate;
                }
                if (synchronizationHistory.status === SynchronizationHistoryStatus.Warning) {
                    synchronizationHistory.warningCount = data.warningCount;
                    synchronizationHistory.errorCount = data.errorCount;
                }

                return synchronizationHistory as SynchronizationHistory;
            }),
    );
