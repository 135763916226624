import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Placeholder, UserRolesIllustration } from 'akeneo-design-system';
import React from 'react';
import { useNavigate } from 'react-router';
import { shopifyAuthenticationUrl } from '../../util/config';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
    align-content: flex-end;
    justify-content: flex-end;
`;
export const StoreScopesOutdated = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Placeholder
                illustration={<UserRolesIllustration />}
                size='large'
                title={intl.formatMessage({
                    id: 'qgsldv',
                    defaultMessage: 'The app permissions of this store are outdated',
                })}
            >
                <FormattedMessage
                    id='PlWGBM'
                    defaultMessage='The app permissions for the Shopify store are not up to date, resulting in missing authorizations that prevent the app from synchronizing your catalog.{br}Please update the app permissions of the Shopify store to enable catalog synchronization.'
                    values={{ br: <br /> }}
                />

                <ButtonsContainer>
                    <Button ghost level='tertiary' onClick={() => navigate('/stores')}>
                        <FormattedMessage id='skQpD/' defaultMessage='Return to Store List' />
                    </Button>

                    <Button
                        level='primary'
                        onClick={() =>
                            location.key
                                ? window.location.replace(
                                      shopifyAuthenticationUrl(location.state.shop),
                                  )
                                : navigate('/stores')
                        }
                    >
                        <FormattedMessage id='m0lA2s' defaultMessage='Update Permissions' />
                    </Button>
                </ButtonsContainer>
            </Placeholder>
        </>
    );
};
